import React from 'react'

const FansOfHyundaiComponent = () => {
  return (
    <div className="col-sm-6 col-md-5 col-lg-6">
    <div className="testimonialInner-box">
      <div>
        <div className="image">
          <img alt="" src="assets/images/testimonial1.jpg" loading="lazy" />
        </div>
        <div className="lower-content">
          <ul className="post-info">
            <li>Venue Owner</li>
          </ul>
          <h4>Ashish, Sandhya, and Divyansh</h4>
          <p>
            <i className="fa fa-quote-left" aria-hidden="true" />
            Thank you, guys, for a wonderful and awesome ride with the
            Tribe. It was great meeting and making new friends. I enjoyed
            every bit of the time spent with you all. A loud shoutout to the
            Hyundai and{" "}
            <span className="readmore">Read More...</span>
            <i className="fa fa-quote-right" aria-hidden="true" />
          </p>
           
        </div>
      </div>
    </div>
  </div>
  )
}

export default FansOfHyundaiComponent