import React from 'react';

function NotFoundPage() {
  return (
    <section className="loginformbox pt-5 pb-5 mtgap50 my-5">
  <div className="container">
    <div className="row justify-content-center align-items-center m-auto">
      <div className="col-sm-6">
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      </div>
      </div>
      </div>
      </section>
  );
}

export default NotFoundPage;
