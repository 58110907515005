import React from 'react';

const AboutSection = () => {
  return (
    <section className="pt-5 pb-5">
      <div className="container">
        <div className="secTitle mb-4">
          <h2>About Hyundai Explorers</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 mb-4">
            <div className="welcom-section">
              <p>
                When it comes to adventure and celebrations, we are your obvious
                choice. Hyundai Explorers is Hyundai owners' community. It's a
                unique ensemble of curious and passionate individuals united by
                the pride of being a Hyundai owner.
              </p>
              <p>
                The Hyundai Explorers team brings you specially curated
                unforgettable escapades to some of the country's most enchanting
                and scintillating locations. Be it an Adventure or a Carnival, you
                create cherished memories with us.
              </p>
              <p>
                While Hyundai SUV-only Adventure, pumps you with thrill and
                exhilaration, Carnival celebrates the ever-growing Hyundai family
                at a larger-than-life scale.
              </p>
              <p>
                So what are you waiting for? Our next extravaganza is arriving
                soon. See you at the starting line.
              </p>
              <ul className="site-list">
                <li>The quest for the unknown.</li>
                <li>The spirit of exploration.</li>
                <li>The spirit of discovery.</li>
              </ul>
              <p>#HyundaiExplorers #HyundaiIndia</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mb-4">
            <div className="img-colarge2 mr-0">
              <div className="colarge-2">
                <img
                  src="assets/images/hyundai-about1.webp"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
