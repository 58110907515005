import React, { useEffect, useState } from 'react';
import CarnivalCarousel from '../Components/CarnivalCarousel';
import CarnivalSubComponent from '../Components/CarnivalSubComponent';
import { baseUrl, newCarnivalApi } from '../APIs/apis';

const Carnival = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "Carnival | HyundaiExplorers";
    const fetchData = async () => {
      try {
        const response = await newCarnivalApi();
        setData(response.carnivalEvents); // Assuming response.data contains the array of carnival data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  return (
    < >
    <CarnivalCarousel  />
    <section className="pt-5 mt-5 pb-5">
      <div className="container pt-3">
        <div className="secTitle pb-4 mb-3">
          <h2>Carnival Event</h2>
        </div>
        <div className="row">
          { data &&data.map((item, index) => (
            <CarnivalSubComponent
              key={index}
              imgUrl={baseUrl + item.thumbnail_image}
              dates={item.dateLabel}
              location={item.location}
              title={item.name}
              slug={item.slug}
            />
          ))}
        </div>
      </div>
    </section>
    </>
    
  );
};

export default Carnival;
