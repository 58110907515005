import React, { useEffect, useState } from "react";
import { Tab ,Nav} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
const FaqPage = () => {
  const [activeTab, setActiveTab] = useState("tab-A");
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  useEffect(() => {
    document.title = "Faq | HyundaiExplorers";
  }, []);

  

  const styles = {
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContentColor: "grey",
    rowTitleFont: "bold",
    rowTitleTextSize: "22px", // Change the font size as needed
    rowTitleFontWeight: "bold", // Change the font weight as needed
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
  };

  return (
    <>
      <section className="faqsbx pt-5 pb-5 ">
        <div className="container my-5">
          {/* <FaqComponent faqData={filterEvent[0]?.faq} /> */}
          <div className="tabpillsbox mt-3 my-2">
            <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
              <Nav
                variant="pills"
                className="nav nav-pills justify-content-center mb-5"
                id="pills-tab"
                role="tablist"
              >
                <Nav.Item className="nav-item">
                  <Nav.Link
                    eventKey="tab-A"
                    className={`nav-link ${
                      activeTab === "adventure" ? "active" : ""
                    }`}
                    id="adventure-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="tab-A"
                    aria-selected={activeTab === "adventure"}
                  >
                    Adventure
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link
                    eventKey="tab-B"
                    className={`nav-link ${
                      activeTab === "carnival" ? "active" : ""
                    }`}
                    id="carnival-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="tab-B"
                    aria-selected={activeTab === "carnival"}
                  >
                    Carnival
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
              <div class="secTitle">
                  <h2>Frequently Ask Questions</h2>
                </div>
                {/* -----adventure faqs ------------ */}
                <Tab.Pane eventKey="tab-A">
                  <Accordion defaultActiveKey="0" className="">
                    {faqDataAdventure &&
                      faqDataAdventure?.map((item, index) => (
                        <Accordion.Item
                          eventKey={index?.toString()}
                          key={index}
                        >
                          <Accordion.Header> {item.question} </Accordion.Header>
                          <Accordion.Body  style={{ padding:'1rem 1.25rem'}}>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.answer }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </Tab.Pane>

                {/*----------- carnival faq-----*/}
                <Tab.Pane eventKey="tab-B">
                <Accordion defaultActiveKey="0" className="">
                    {carnivalFaqData &&
                      carnivalFaqData?.map((item, index) => (
                        <Accordion.Item
                          eventKey={index?.toString()}
                          key={index}
                        >
                          <Accordion.Header> {item.question} </Accordion.Header>
                          <Accordion.Body  style={{ padding:'1rem 1.25rem'}}>
                            <div
                           
                              dangerouslySetInnerHTML={{ __html: item.answer }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>{" "}
      </section>
    </>
  );
};

// -----Adventure faq data-----
const faqDataAdventure = [
  {
    question: "Who organizes the Hyundai Explorer Drives?",
    answer:
      "Innocean Worldwide Communication Private Limited (“IIN”), authorized by Hyundai Motor India Limited (“Hyundai”) to undertake and organize Hyundai Explorer Drives (the “Drives”) and related activities, has engaged, on a principal-to-principal basis, Cougar Motorsport Private Limited (“Cougar”), an entity engaged in the business of organising driving expeditions. Herein, Cougar its employees, affiliates, sub-contractors, vendors, etc. are referred to as the “Organisers”.",
  },
  {
    question: "Who is eligible to participate?",
    answer:
      "The Drives are open exclusively to Indian nationals, above 18 years of age, residing in India, who own Hyundai SUV (“Vehicle”). Only such owners of the vehicle, along with their friends and family members are eligible to participate in the Drives. The Organisers have complete control over all registration and participation, and have the right to refuse anyone without providing a reason.",
  },
  {
    question: "Can I take part in every event?",
    answer:
      "No, any Participant is allowed to enrol for a maximum of two (2) similar duration Drives in any given year and the Entry Fee for each drive will be independent and separate. Please note that the Terms and Conditions, including the Event Fee and the eligibility criteria may differ  certain Drives due to specific climate issues, drive/road/route conditions.",
  },
  {
    question: "How many Participants can be in each vehicle?",
    answer:
      "Each vehicle is allowed a maximum number of 3 people for each Drive. Minimum age for participation is 5 years and above.",
  },
  {
    question: "Can children participate?",
    answer:
      "Yes, children 5 years and above are eligible to participate in the Drives in the company of their parents/guardians.",
  },
  {
    question: "Can my driver accompany me on the trip?",
    answer:
      "Participants are not permitted to bring their personal drivers or chauffeurs; this is entirely a self-drive trip designed only for our Hyundai SUV Owners.",
  },
  {
    question: "Who is eligible to drive?",
    answer:
      "Participants with a valid driving license are eligible to drive their own vehicles on a Drive. The Participant will be required to produce his/her driving license in original at the time of registration, along with all valid legal documents of the vehicle including but not limited to vehicle registration certificate, comprehensive insurance, pollution under control (PUC) certificate and letter of authority if the vehicle is owned by next of kin.",
  },
  {
    question: "Do I need to have a certain level of physical and mental fitness to take part?",
    answer:
      "The Drive that you have booked is an adventure event that can place considerable demands on man and material. Driving on difficult terrain / in difficult track conditions may lead to extreme stress. Sand, dust, high humidity and unfavourable weather conditions etc. may impact upon you and your personal equipment. Fundamentally, the Drives are suitable only for fully fit, healthy both physically and mentally, resilient adults. Kindly note that specific Drives may have differing terms and conditions based on climate issues and road conditions. Participants are advised to carefully review the terms and conditions related to the specific Drive before booking to ensure a safe and enjoyable experience for themselves and their family. Additionally, the Organisers reserve the right to take the necessary measures, including but not limited to limiting the Participant's participation, if they believe at any time that the person is impaired by any intoxicating substance.",
  },
  {
    question: "What is included in the price of the Drive?",
    answer:
      "The price of each Drive includes twin sharing accommodation, 3 meals per day, and all necessary legal permits as per the itinerary and activities in order to make the experience truly memorable. Kindly refer to the Terms and Conditions for more details.",
  },
  {
    question: "What is not included in the price of the Drive?",
    answer:
      "The price of each Drive does not include flight tickets to and from the event, vehicle related expenses (such as fuel, tolls, traffic fines, repairs, service, etc.), insurances, meals not specifically mentioned in the itinerary, alcoholic beverages and any kind of personal expenses or optional tours which the Participants may choose to avail. Kindly refer to the Terms and Conditions for more details. ",
  },
  {
    question: "Is the cost on a per-person basis?",
    answer:
      "The cost of each Drive is for 2 Participants per vehicle. Children will be charged as an additional Participant. Kindly refer to the terms and conditions for more details. ",
  },
  {
    question: "When is the last day to make the payment?",
    answer:
      "The online bookings and registrations are on a first come first served basis and will be confirmed only upon receipt of the full amount for the specific Drive. The booking page will be closed automatically once all slots are booked with payment confirmation.",
  },
  {
    question: "How can I make the payment?",
    answer:
      "Payments can be effected through net banking, credit card, debit card and UPI on our website. ",
  },
  {
    question: "How will I receive confirmation of my participation in the Drive?",
    answer:
      "Firstly, you will have to register / RSVP on the Hyundai Explorers website, www.hyundaiexplorers.com/, interfaced/linked with the website, www.hyundai.com/in. Upon registration, you will receive a confirmation call with all details of the specific Drive which you have chosen. Your participation in the Drive will only be confirmed once we receive the payment. We kindly request you to finalize your travel plans only upon receiving official confirmation of your participation in the Drive from us. In case no official confirmation is received by you for participation in the Drive, it shall be deemed that your participation has not been accepted and the refund amount will be remitted to the same bank account/card from which Entry Fee was received basis refund and cancellation policy in terms & conditions. ",
  },
  {
    question: "Do we travel in a convoy?",
    answer:
      "Yes, subject to road and weather conditions, we will generally travel in a convoy using radio communication between vehicles. ",
  },
  {
    question: "Is there a specific type of luggage I should bring?",
    answer:
      "We do not have any specific recommendations with regards to the type of luggage each Participant should carry, as long as it can be accommodated and secured within the vehicle without causing a hinderance to the other passengers and their safety. ",
  },
  {
    question: "What is the dress code for evenings?",
    answer:
      "The evening dress code will be casual attire.",
  },
  {
    question: "Are the itineraries and accommodation confirmed?",
    answer:
      "The itineraries and accommodation are subject to Events of Force Majeure and other factors. Kindly refer to the Terms and Conditions for more details.",
  },
  {
    question: "What is the cancellation policy?",
    answer: "In the event that a Participant cancels his/her participation in a Drive:\n\na. 30 or more days prior to the Drive start date, 75% of the Entry Fee will be refunded.\nb. 15 to 29 days prior to the Drive start date, 50% of the Entry Fee will be refunded.\nc. 14 days or less to the Drive start date, no refunds will be entertained and the entire cost of the Drive will be forfeited.\nd. All refunds will take up to 14 working days to process. The refund amount will be remitted to the same bank account/card from which Entry Fee was received.\ne. The Entry Fee paid will be fully refunded in case the registration is rejected by the Organisers or in case a Drive is cancelled by the Organisers (except for reasons outside their control). The Entry Fee will not be refunded for any other reason (including Force Majeure Events) except those agreed above.\nf. The Entry Fee charged by the Organisers is non-transferable and non-interchangeable with any goods and services by Cougar, Hyundai and/or any third party involved in the Drive. \n\nKindly refer to the Terms and Conditions for more details."
  },
  {
    question: "What are the COVID protocols for the Drives?",
    answer: "It is mandatory for all Participants to be fully vaccinated against Covid-19 and produce the vaccination certificates for both doses before the start of the Drive. ",
  },
  {
    question: "Can I still participate if I do not have a partner?",
    answer: "The charges and procedures elaborated above will remain the same even if you're travelling by yourself.",
  },
  {
    question: "Are the Drives suitable for my family?",
    answer: "The Drives you are enrolling are adventure drives and every individual drives might have different elements, it is advisory to check the drive details and terms & conditions before you enrol for any drive. The Drives will be planned to suit the convenience of any accompanying family members of the Participants. However, we may not be able to arrange for special assistance and diets, if required, for elderly and specially abled Participants.",
  },
  {
    question: "Are the Drives suitable for women?",
    answer: "The Drives are planned by the Organisers to suit the convenience of women Participants. However, it is advisable to check the drive details and terms & conditions of each drive before enrolling for it.",
  },
  {
    question: "What type of accommodation will be provided to us?",
    answer: "The Organisers will make best efforts to procure the best hotels available in the price range, prioritizing the comfort and convenience of the Participants as per the itinerary. The selection of hotels will be based on a strong internal assessment. However, it is important to note that the allotment of rooms is not performed by the Organizers. Rooms are allocated through the hotel on a first-come, first-served basis, based on the registration and submission of required documents by the customers. It should be understood that all rooms booked are of the same category and type, hence the allotment of specific rooms is not predetermined. In certain drives, due to the location, Organizers may have to book multiple hotels based on any restrictions pertaining to room availability. In such cases as well, the allotment of rooms will be based on the first-come, first-served basis of registration and documentation submission.",
  },
  {
    question: "What kind of experiences are we signing up for?",
    answer: "All experiences will be designed to bring alive the spirit of Hyundai Explorers Adventure. These may include scenic drives, nature/forest safaris, visits to sunrise and sunset points, local cultural programs, music and entertainment and various other activities to bring alive the Explorer in you. All drives Itinerary and key highlights are different, it is advisable to read the details of the drive before enrolling for any drive. ",
  },
  {
    question: "If I am unable to get my own vehicle, can I participate with a rented vehicle or will Hyundai provide a vehicle from their end?",
    answer: "The Drives are only for Hyundai SUV owners, and all participating vehicles must be owned by the Participants or their next of kin. ",
  },
  {
    question: "Can arrangements be made for the transportation of my vehicle?",
    answer: "No, we will not be able to make any arrangements for transportation or safe-keeping of Participants' vehicles. ",
  },
  {
    question: "Whom can I contact or write to if I require further information, assistance or clarification?",
    answer: "These FAQs are not binding in nature and are meant only for reference purposes. The Participants shall decide on participation only after reading and understanding the terms and conditions of each Drive. In case of any contradiction between the terms of the Drive and the contents of this FAQ, the terms of the Drive shall prevail to the extent of such contradictions.\n\nWe will gladly assist you and can be contacted as follows:\n\nE-mail: info@hyundaiexplorers.com\nTelephone: +91 8000 85 1616 (10:00 - 19:00 (IST), Monday to Saturday)",
  } 
];

// ----carnival faq data
const carnivalFaqData = [
  {
    question: "Who organizes Hyundai Explorers Carnival?",
    answer:
      "Innocean Worldwide Communication Private Limited (“IWI”), authorized by Hyundai Motor India Limited (“Hyundai”) to undertake and organize Hyundai Explorer carnival and related activities, has engaged, on a principal-to-principal basis, ESP 360 degree solution Pvt. Ltd (“ESP”), an entity engaged in the business of organising Hyundai Explorers Carnival. Herein, ESP its employees, affiliates, sub-contractors, vendors, etc. are referred to as the “Organisers”.",
  },
  {
    question: "Who is eligible to participate?",
    answer:
      "The Hyundai Explorers Carnival are exclusively planned for persons who own Hyundai vehicles. Only Hyundai owners, along with their friends and family members are eligible to participate in the Hyundai Explorers Carnival. The Organisers have complete control over all registration and participation, and have the right to refuse anyone without providing a reason.",
  },
  {
    question: "How many participants can be in each vehicle?",
    answer:
      "Each vehicle is allowed to register with a maximum number of 4 people for the Carnival. Minimum age for participation is 5 years and above.",
  },
  {
    question: "Can children participate?",
    answer:
      "Yes, children 5 years and above are eligible to participate in the Carnival and activities during the carnival in the company of their parents/guardians.",
  },
  {
    question: "Can my driver accompany me at the Carnival and activities?",
    answer:
      "Participants are not permitted to bring their personal drivers or chauffeurs; this is entirely a self-drive trip/carnival designed only for our Hyundai cars Owners.",
  },
  {
    question: "Can I take part in every Hyundai Explorers Carnival?",
    answer:
      "Yes, and Hyundai car owner can participate and register for all Hyundai Explorers Carnival. However, the registration is basis first come first serve basis as per the T&C.",
  },
];
export default FaqPage;
