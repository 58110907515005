 

 import React, { useEffect, useState } from "react";
import TestimonialPageComponent from "../Components/TestimonialPageComponent";
import { getLiveTestimonialAPI } from "../APIs/apis";

const Testimonial = () => {
  
  const [testimonial, setTestimonial] = useState([])
  const getTestimonialData = async () => {
    try {
      const res = await getLiveTestimonialAPI();
      
      setTestimonial(res.data);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    document.title = "Testimonials | HyundaiExplorers";
    getTestimonialData();
  }, []);
  return (
    <section className="mt-5 py-5">
      <div className="container pt-3">
        <div className="secTitle pb-4 mb-3">
          <h2>CUSTOMER TESTIMONIALS</h2>
        </div>
        <div className="row justify-content-center">
          {testimonial &&
           testimonial.map((item, index) => (
              <TestimonialPageComponent key={index} data={item} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;


