import React, { useEffect, useState } from 'react';
import AdventureCarousel from '../Components/AdventureCarousel';
import AdventureSubComponent from '../Components/AdventureSubComponent';
import { baseUrl, newAdventureApi } from '../APIs/apis';

const Adventure = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "Adventure | HyundaiExplorers";
    const fetchData = async () => {
      try {
        const response = await newAdventureApi();
        setData(response.adventureEvents); // Assuming response.data contains the array of adventure data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  return (
    < >
    <AdventureCarousel  />
    <section className="pt-5 mt-5 pb-5">
      <div className="container pt-3">
        <div className="secTitle pb-4 mb-3">
          <h2>Adventure Event</h2>
        </div>
        <div className="row">
          {  data && data.map((item, index) => (
            <AdventureSubComponent
              key={index}
              imgUrl={`${baseUrl}${item.thumbnail_image}`}
              // {`${baseUrl}${event.show_count_url}`}
              dates={ item.dateLabel}
              location={ item.location}
              title={item.name}
              slug={item.slug}
              event_status={item.event_status}
            />
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

export default Adventure;
