import axios from "axios";
 
export const baseUrl =  process.env.REACT_APP_BACKEND_URL 
export const serverType = process.env.REACT_APP_SERVER_TYPE || "live"; 
const API = axios.create({ baseURL: baseUrl+"/api" });

const appendServerType = (url) => {
  // Check if the URL already has query parameters
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}server_type=${serverType}`;
};

const makeAPICall = async (method, url, data) => {
  try {
    const urlWithParams = appendServerType(url);
    //console.log('server type: ',process.env.REACT_APP_SERVER_TYPE);
    //console.log('url: ', urlWithParams);
    // Make the API call without any headers
    const response = await API[method](urlWithParams, data);
    
    return response.data;
    
  } catch (error) {
    console.log("API request failed:", error);
    return error;
  } 
};
const makeAPICallData = async (method, url, data) => {
  try {
    // Make the API call without any headers
    const response = await API[method](url, data);
    //console.log(response.data)
    return response;
    
    
  } catch (error) {
    console.log("API request failed:", error);
    return error;
  } 
};

  

export const getCategory = async () =>
  await makeAPICall("get", "/category");

  
  // APIs/apis.js


export const getSingleEventsApi =async (slugName) =>
await makeAPICall("get", `/event/single-event?slug=${slugName}`);

  export const getAllEventsApi =async () =>
  await makeAPICall("get", "/event");

  export const getAllGalleryApi =async (eventId) =>
  await makeAPICall("get", `/gallery/galleryByEventId/${eventId}`);


  // home carousel api
  export const gethomeCarouselAPI = async  ()=>
    await makeAPICall("get", "/cms/gethomeCarousel");
   // home carousel api
  export const getadventureCarouselAPI = async  ()=>
    await makeAPICall("get", "/cms/getadventureCarousel");

    // home carousel api
  export const getcarnivalCarouselAPI = async  ()=>
    await makeAPICall("get", "/cms/getcarnivalCarousel");
  
    // login api
    export const loginAPI = async  (data)=>
    await makeAPICall("post", "/user/login",data);

    export const userRegistrationAPI  =async  (data)=>
    await makeAPICall("post", "/user/userRegistration",data);

    export const emailVerificationAPI = async  (email,verificationToken)=>
    await makeAPICall("get", `/user/verify/${email}/${verificationToken}`);

    export const updatePasswordAPI = async (email,data) =>
    await makeAPICall("post",`/user/updatePassword/${email}`,data)


    export const getAllAdventureDataAPI = async  ()=>
    await makeAPICall("get", "/event/adventure-events");

    export const getAllCarnivalDataAPI = async  ()=>
    await makeAPICall("get", "/event/carnival-events");

    //Arsh New API
    export const getActiveAllAdventureDataAPI = async  ()=>
    await makeAPICall("get", "/event/active-events-adventure");

    export const getActiveAllCarnivalDataAPI = async  ()=>
    await makeAPICall("get", "/event/active-events-carnival");

    export const showGalleryAPI = async  ()=>
    await makeAPICall("get", "/event/show-gallery");


    export const newAdventureApi = async  ()=>
    await makeAPICall("get", "/event/new-adventure");

    export const newCarnivalApi = async  ()=>
    await makeAPICall("get", "/event/new-carnival");

    






    export const createEventRegistrationAPI = async  (data)=>
    await makeAPICallData("post", "/event-registration/create",data);

    export const updateEventRegistrationAPI = async (id, data) =>
    await makeAPICall("put", `/event-registration/update/${id}`, data);
  
    export const forgetPasswordAPI = async  (data)=>
    await makeAPICall("post", "/user/redirectForgotPassword",data);
    
    export const resetForgetPasswordAPI = async  ( token, data)=>
    await makeAPICall("post", `/user/resetPassword/${token}`,data);

    export const getEventDataAPI = async  (eventId)=>
    await makeAPICall("get", `/event/get-event-details/${eventId}`);

    export const getAllEventsNewApi =async () =>
     await makeAPICall("get", "/event/events-new");

     export const getAdventureEventsNewApi =async () =>
     await makeAPICall("get", "/event/events-adventure");

     export const getCarnivalEventsNewApi =async () =>
     await makeAPICall("get", "/event/events-carnival");
     
     
 //  interstedUser 
     export const createNewInterestedUser = async  ( data)=>
     await makeAPICall("post", "/interested_user/create",data);
     


    //  add new apis
 
    
    export const getLiveTestimonialAPI =async () =>
    await makeAPICall("get", "/testimonial/get_live_testimonials");

    export const getLiveSocialsAPI =async () =>
    await makeAPICall("get", "/social_content/get_live_socials");

    
  



