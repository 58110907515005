//DEPENDENCY IMPORTS
import { ToastContainer, toast } from "react-toastify";

// CSS IMPORTS
import "react-toastify/dist/ReactToastify.css";
import "./notify.css";
  
// IMPORT THIS FUNCTION AND USE  
export const Notify = (action, msg) => {
  toast[action](msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      fontSize: "16px",
      top: "60px",
      
    },
  });
};

// NO NEED TO IMPORT AND USE IT.
// THIS COMPONENENT IS ALREADY INSERTED AT THE TOP LABLE OF THE APPLICATION (App.js)
export const Toast = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />
);