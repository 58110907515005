import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { showGalleryAPI } from "../APIs/apis";
import ShowGalleryComponent from './SubComponent/ShowGalleryComponent';

const HomeGallery = () => {
  const [galleryData, setGalleryData] = useState(null);
  const [activeYear, setActiveYear] = useState("All");

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await showGalleryAPI();
      
        setGalleryData(response);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      }
    };

    fetchGalleryData();
  }, []);

  const handleYearChange = (year) => {
    setActiveYear(year);
  };

  let filteredEvents = [];
  if (galleryData) {
    filteredEvents = activeYear === "All" ? 
      galleryData.archivedEventsWithImages : 
      galleryData.archivedEventsWithImages.filter(event => event.year === activeYear);
    
    // Limiting to maximum 6 events
    filteredEvents = filteredEvents.slice(0, 6);
  }

return (
    <section className="pt-4 pb-5">
        <div className="container">
            <div className="secTitle pb-4">
                <h2>#HyundaiExplorers</h2>
            </div>
            <div className="tabpillsbox mt-3">
                <ul
                    className="nav nav-pills justify-content-center mb-5"
                    id="pills-tab"
                    role="tablist"
                >
                    {galleryData && galleryData.year.map((year, index) => (
                        <li className="nav-item" key={index}>
                            <Link
                                className={`nav-link ${activeYear === year ? "active" : ""}`}
                                onClick={() => handleYearChange(year)}
                            >
                                {year}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="allevents"
                        role="tabpanel"
                        aria-labelledby="allevents-tab"
                    >
                        <div className="row">
                            <ShowGalleryComponent allGallery={filteredEvents} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

};

export default HomeGallery;
