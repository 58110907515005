import React from "react";
import { Link } from "react-router-dom/dist";

const AdventureSubComponent = ({
  imgUrl,
  title,
  dates,
  location,
  slug,
  event_status,
}) => {
  return (
    <div className="col-sm-4 mb-4">
      <div className="eventsmainbx">
        <figure>
          <img src={imgUrl} className="img-fluid" alt="" loading="lazy" />
        </figure>
        <figcaption>
          <div className="d-sm-flex justify-content-sm-between">
            <h2>{title} </h2>
          </div>
          {event_status === "upcoming" && dates === "" ? (
            ""
          ) : (
            <h4 className="datebx">
              <i className="fa fa-calendar" aria-hidden="true" />
              <span dangerouslySetInnerHTML={{ __html: dates }} />
            </h4>
          )}
          {event_status === "upcoming" && location === "" ? (
            ""
          ) : (
            <p className="mb-0">
              <i className="fa fa-map-marker" aria-hidden="true" /> {location}
            </p>
          )}
          <div className="d-sm-flex justify-content-sm-between mt-2">
            {event_status === "upcoming" ? (
              <Link className="btn btn-primary w-100"  >
                Upcoming
              </Link>
            ) : (
              <Link className="btn btn-primary w-100" to={`/event/${slug}`}>
                Know More
              </Link>
            )}
          </div>
        </figcaption>
      </div>
    </div>
  );
};

export default AdventureSubComponent;
