import React, { useEffect, useState, useRef } from "react";
 
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { Notify } from "../Components/Notify";
import { Modal, Form, Button } from "react-bootstrap";
import {
  createEventRegistrationAPI,
  getSingleEventsApi,
  updateEventRegistrationAPI,
} from "../APIs/apis";
import swal from "sweetalert";
var query = require("india-pincode-search");

const RegistrationCarnivalPage = () => {
  const navigate = useNavigate();
  // const merchantKey = "Cv7XMvgZ8fths";
  const slugId = useParams().slugId;

  const [newForm, setNewForm] = useState({});
  const [user, setUser] = useState("");
  const [dob, setDob] = useState("");
  const [eventId, setEventId] = useState();
  const [termCondition, setTermCondition] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [guestForm, setGuestForm] = useState(0);
  const [guestDetails, setGuestDetails] = useState(
    Array.from({ length: guestForm }, () => ({}))
  );
  const [maxGuestAllowed, setMaxGuestAllowed] = useState(0); // New state for maxGuestAllowed
  const [eventData, setEventData] = useState(null); // Add a state for eventData
  const [validationErrors, setValidationErrors] = useState({});
  const firstNameRef = useRef(null);
  const eventRegisterIdRef = useRef(null);
  const razorpayRef = useRef(null);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [vehicles, setVehicles] = useState([]); // State for vehicles

  // pincode
  const [pincode, setPincode] = useState("");

  useEffect(() => {
    // Define vehicles based on eventData
    let vehicles = [];

    if (eventData) {
      if (eventData.category === "Adventure") {
        vehicles = [
          { label: "Select Vehicle", value: "" },
          { label: "Tucson", value: "Tucson" },
          { label: "Alcazar", value: "Alcazar" },
          { label: "Creta", value: "Creta" },
          { label: "Venue", value: "Venue" },
          { label: "Ioniq5", value: "Ioniq5" },
          { label: "Exter", value: "Exter" },
        ];
      } else if (eventData.category === "Carnival") {
        vehicles = [
          { label: "Select Vehicle", value: "" },
          { label: "Xcent", value: "Xcent" },
          { label: "Exter", value: "Exter" },
          { label: "Venue", value: "Venue" },
          { label: "Creta", value: "Creta" },
          { label: "Alcazar", value: "Alcazar" },
          { label: "Tucson", value: "Tucson" },
          { label: "i20", value: "i20" },
          { label: "i10 Nios", value: "i10 Nios" },
          { label: "Aura", value: "Aura" },
          { label: "Ioniq5", value: "Ioniq5" },
          { label: "Santro", value: "Santro" },
          { label: "i10", value: "i10" },
          { label: "Grand i10", value: "Grand i10" },
          { label: "Verna", value: "Verna" },
          { label: "Elantra", value: "Elantra" },
        ];
      }
    }
    setVehicles(vehicles);
  }, [eventData]);

  useEffect(() => {
    if (eventData) {
      const maxGuestAllowed = eventData.max_guest_allowed || 0;
      setMaxGuestAllowed(maxGuestAllowed);
    }
  }, [eventData]);
  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async () => {
      try {
        const eventResponse = await getSingleEventsApi(slugId);
        // console.log('eventData');
        const eventDetailData = eventResponse.event;

        //console.log(eventData)
        setEventData(eventDetailData);
        setEventId(eventDetailData._id);

        // const maxGuestAllowed = eventDat  // Set maxGuestAllowed based on eventData
        const initialFormValues = {
          firstName: "",
          lastName: "", // Add other fields as needed
          phone: "",
          email: "",
          pinCode: "",
          state: "",
          city: "",
          gender: "",
        };

        setNewForm((prevForm) => ({ ...prevForm, ...initialFormValues }));
      } catch (error) {
        console.error("Error fetching event data:", error);
      }

      // Set document title
      document.title = "Event Registration | HyundaiExplorers";

      // Set user data from token
    };

    fetchData();

    const loadRazorpay = () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.onload = () => {
        initializeRazorpay();
      };
      document.body.appendChild(script);
    };

    const initializeRazorpay = () => {
      const instance = new window.Razorpay({
        key:
          eventData && eventData.payment_gateway_key
            ? eventData.payment_gateway_key
            : "rzp_test_SKvHcCID8PprEU", // Replace with your actual Razorpay API key
      });
      razorpayRef.current = instance;
    };

    loadRazorpay();
  }, [slugId, dob]);

  const handleFileUpload = (fieldName, e) => {
   
    const fileValue=2 * 1024 * 1024
    const file = e.target.files[0];
    if (file) {
      // Check if file size is within the limit (2MB)
      if (file.size <= fileValue ) { // Convert MB to bytes
        setNewForm({
          ...newForm,
          [fieldName]: file,
        });
      } else {
        // If file size exceeds the limit, show an error message or handle it accordingly
        alert(`File size exceeds the limit (2MB). Please select a smaller file.`);
        // Optionally, you can clear the file input field
        e.target.value = null;
      }
    }
  };
  

  const handleGuestInputChange = (index, key, value) => {
    const updatedGuests = [...guestDetails];
    const guest = updatedGuests[index] || {};
    guest[key] = value;
    updatedGuests[index] = guest;
    setGuestDetails(updatedGuests);
  };

  const user_id = null;
  // ------------------------------handle submit data-----------------------

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    setValidationErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      displayValidationErrors(validationErrors);
      return;
    }
    const sequentialExecution = async () => {
      try {
        const resultA = await appendFormData();
        const { finalPr, formData } = await calculateAndSetFinalPrice(resultA);
        const response = await updateAPI(finalPr, formData);
        console.log("first result:", response.data);
        if (response.status === 201) {
          // console.log("first result:", response.data);
          localStorage.setItem("event_register_id", response.data.eventId);
          // Navigate to payment page
          // navigate(eventData.payment_gateway_url);
          window.location.href=eventData.payment_gateway_url
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    sequentialExecution();
  };
  
  const updateAPI = (priceAmount, formData) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          formData.append("totalPaymentAmount", priceAmount);
          const response = await createEventRegistrationAPI(formData);
          setNextButtonDisabled(nextButtonDisabled);
          resolve(response); // Resolve with the response data
        } catch (error) {
          reject(error); // Reject with the error
        }
      }, 500);
    });
  };
  
  const validateForm = () => {
    const errors = {};
    const firstNameError = validateFirstName();
    if (firstNameError) {
      errors.firstName = firstNameError;
    }

    const emailError = validateEmail();
    if (emailError) {
      errors.email = emailError;
    }

    const ageError = validateAge();
    if (ageError) {
      errors.dob = ageError;
    }

    if (!newForm.phone) {
      errors.phone = "Enter Mobile Number";
    }
    if (!newForm.city) {
      errors.city = "Enter City Name";
    }
    if (!newForm.state) {
      errors.state = "Enter State Name";
    }
    if (!newForm.pinCode || !/^\d{6}$/.test(newForm.pinCode || pincode)) {
      errors.pinCode = !newForm.pinCode
        ? "Enter Pin Code"
        : "Pin Code must be a 6-digit number";
    }
    if (!newForm.gender) {
      //validationErrors.push("Select Gender");
    }
    if ((newForm.address && newForm.address.length < 5) || !newForm.address) {
      errors.address = "Address should be at least 5 characters";
    }
    if (!newForm.vehicle_owned) {
      errors.vehicle_owned = "Select Any Vehicle";
    }

    if (!newForm.vin) {
      errors.vin = "Enter VIN/Chassis Number";
    }
    if (!termCondition) {
      errors.termCondition = "Please check Terms and Conditions";
    }
    if (!newForm.car_reg_no) {
      errors.car_reg_no = "Enter Car Registration Number";
    }

    if (!newForm.drive_no) {
      errors.drive_no = "Enter Driving license Number";
    }
    if (!newForm.rc_frontside_file || newForm.rc_frontside_file.length === 0) {
      errors.rc_frontside_file = "Please Upload RC Front Side Image";
    }

    if (!newForm.rc_backside_file || newForm.rc_backside_file.length === 0) {
      errors.rc_backside_file = "Please Upload RC Back Side Image";
    }

    if (!newForm.license_backside_file || newForm.license_backside_file.length === 0) {
      errors.license_backside_file ="Please Upload License Back Side Image";
    }
    if (!newForm.license_file || newForm.license_file.length === 0) {
      errors.license_file="Please Upload License Front Side Image";
    } 

    return errors;
  };
  const validateFirstName = () => {
    if (!newForm.firstName) {
      return "Enter First Name";
    }
    return null;
  };

  const validateEmail = () => {
    if (!newForm.email || !/\S+@\S+\.\S+/.test(newForm.email)) {
      return !newForm.email ? "Enter Email" : "Invalid Email Format";
    }
    return null;
  };

  const validateAge = () => {
    const dobValue = newForm.dob || dob;
    const dobDate = new Date(dobValue);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dobDate.getFullYear();

    if (age < 18) {
      return "Age must be 18 or older";
    }
    return null;
  };

  const displayValidationErrors = (errors) => {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        var element = document.getElementById(key);
        if (element) {
          element.focus();
          return;
        }
      }
    }
  };

  const appendFormData = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const formData = new FormData();
        // Append form data
        for (const key in newForm) {
          if (newForm.hasOwnProperty(key)) {
            formData.append(key, newForm[key]);
          }
        }
        const eventName = eventData?.name;
        const uploadDirectory = `upload/events/${eventName
          .replace(/\s+/g, "_")
          .toLowerCase()}/document`;
        formData.append("directory", uploadDirectory);
        formData.append("user_id", user_id);
        formData.append("eventId", eventId);
        formData.append("colleagues", JSON.stringify(guestDetails));
        
        resolve(formData);
      }, 500);
    });
  };

  const calculateAndSetFinalPrice = (formData) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        let finalPr = 0;
        if (eventData.basic_price_allowed_person > guestForm) {
          finalPr = eventData.price;
        } else {
          finalPr =
            (guestForm - eventData.basic_price_allowed_person) *
              eventData.additional_price +
            eventData.price;
        }

        // This is the final price initiate the payment gateway from here, use dummy user information I will change
       
        resolve({ finalPr: finalPr, formData: formData });
      }, 1000);
    });
  };

  
  const generateOptions = (finalPriceAmount) => {
    return {
      key:
        eventData && eventData.payment_gateway_key
          ? eventData.payment_gateway_key
          : "rzp_test_SKvHcCID8PprEU",
      amount: finalPriceAmount * 100,
      currency: "INR",
      name:
        eventData && eventData.company_name
          ? eventData.company_name
          : "Hyundai",
      description:
        eventData && eventData.payment_description
          ? eventData.payment_description
          : "Hyundai",
      handler: async function (response) {
        try {
          const data = {
            paymentStatus: "Paid",
            razorpay_payment_id: response?.razorpay_payment_id,
            paymentDescription: "Payment Success",
          };
          const result = await updateEventRegistrationAPI(
            eventRegisterIdRef.current,
            data
          );
           
          if (result.payment_status === "Paid") {
            navigate("/thank-you");
          } else {
            swal({icon:"error",title: "There is some issue occured!"});
          }
        } catch (error) {
          console.error(error);
        }
      },
      modal: {
        ondismiss: async function () {
          try {
            const data = {
              paymentStatus: "Pending",
              razorpay_payment_id: null,
              paymentDescription: "Payment gateway modal close 2",
            };
            const result = await updateEventRegistrationAPI(
              eventRegisterIdRef.current,
              data
            );
            console.log("close", result);
            swal({icon:"error", title:"Payment Gateway Modal closed"});
            if (result.status === 200) {
              swal({icon:"success", title:"Payment Gateway Modal closed"});
            }
          } catch (error) {
            console.error(error);
          }
        },
        onerror: async function (error) {
          try {
            const data = {
              paymentStatus: "Error",
              razorpay_payment_id: null,
              paymentDescription: "Payment gateway error",
            };
            const result = await updateEventRegistrationAPI(
              eventRegisterIdRef.current,
              data
            );
            console.log("error", result);
            swal({icon:"error", title:"Payment Gateway Error"});
            if (result.status === 200) {
              swal({icon:"error", title:"Payment Gateway Error"});
            }
          } catch (error) {
            console.error(error);
          }
        },
      },
      
      prefill: {
        name: newForm.firstName,
        email: newForm.email,
        contact: newForm.phone,
      },
      theme: {
        color: "#3399cc",
      },
    };
  };

  // -----pincode api----------------------
  const [message, setMessage] = useState();
  const getPincodeAddress = async () => {
    try {
      // Check if pincode is not null, not just spaces, and has a length of 6 or more
      if (pincode.trim() !== "" && pincode.length >= 6) {
       
        const res = query.search(pincode);
        setNewForm({
          ...newForm,
          city: res[0].city,
          state: res[0].state,
        });

         
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  useEffect(() => {
    getPincodeAddress();
  }, [pincode]);

  return (
    <section className="loginformbox pt-5 mt-3 my-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="formheading">
              <h2>
                <span> Ready To Explore {eventData?.name} ?</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="formcontrollerbx bg-mode shadow rounded-3 overflow-hidden p-4">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {/* Form START */}
              <form className="form mt-0 text-left">
                <div className="form-row">
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        id="firstName"
                        className="form-control"
                        placeholder="First Name"
                        required
                        ref={firstNameRef}
                        value={
                          newForm.firstName === undefined
                            ? user.firstName
                            : newForm.firstName
                        }
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            firstName: e.target.value,
                          })
                        }
                      />
                      <label>First Name *</label>
                      {validationErrors.firstName &&  !newForm.firstName && (
                        <p className="text-danger">
                          {validationErrors.firstName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        required
                        value={
                          newForm.lastName === undefined
                            ? user.lastName
                            : newForm.lastName
                        }
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            lastName: e.target.value,
                          })
                        }
                      />
                      <label>Last Name </label>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-5 col-lg-5">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        id="phone"
                        className="form-control"
                        placeholder="Mobile number*"
                        required
                        value={
                          newForm.phone === undefined
                            ? user.mobileNo
                            : newForm.phone
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (
                            /^\d*$/.test(inputValue) &&
                            inputValue.length <= 10
                          ) {
                            setNewForm({
                              ...newForm,
                              phone: inputValue,
                            });
                          }
                        }}
                      />

                      <label>Mobile Number *</label>
                      {validationErrors.phone && !newForm.phone && (
                        <p className="text-danger">{validationErrors.phone}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-5 col-lg-5">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        id="email"
                        className="form-control"
                        placeholder="E-mail ID*"
                        required=""
                        value={
                          newForm.email === undefined
                            ? user.email
                            : newForm.email
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Always update the state with the entered value
                          setNewForm({
                            ...newForm,
                            email: inputValue,
                          });

                          // Validate the email format and set the error accordingly
                          if (
                            inputValue !== "" &&
                            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)
                          ) {
                            setValidationErrors({
                              ...validationErrors,
                              email: "Invalid Email Format",
                            });
                          } else {
                            setValidationErrors({
                              ...validationErrors,
                              email: "", // Clear the error if the format is valid
                            });
                          }
                        }}
                      />

                      <label>E-mail ID *</label>
                      {validationErrors.email &&  !newForm.email &&(
                        <p className="text-danger">{validationErrors.email}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-2 col-lg-2">
                    <div className="form-group mb-4">
                      <input
                        type="date"
                        id="dob"
                        className={`form-control ${
                          validationErrors.dob ? "has-error" : ""
                        }`}
                        placeholder="Date of birth*"
                        required
                        defaultValue={dob}
                        value={newForm.dob === undefined ? dob : newForm.dob}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            dob: e.target.value,
                          })
                        }
                      />
                      <label>Date of Birth *</label>
                      {validationErrors.dob && !newForm.dob && (
                        <p className="text-danger">{validationErrors.dob}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-4 col-sm-3 col-lg-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                      required
                      value={
                        newForm.pinCode === undefined
                          ? user.pinCode
                          : newForm.pinCode
                      }
                      onChange={(e) => {
                        setPincode(e.target.value);
                        setNewForm({
                          ...newForm,
                          pinCode: e.target.value,
                        });
                      }}
                    />
                    <label>Pincode *</label>

                    {validationErrors.pinCode && !newForm.pinCode && (
                      <p className="text-danger">{validationErrors.pinCode}</p>
                    )}
                  </div>
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        id="city"
                        className="form-control"
                        placeholder="City"
                        required
                        value={
                          newForm.city === undefined ? user.city : newForm.city
                        }
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            city: e.target.value,
                          })
                        }
                      />
                      <label>City *</label>
                      {validationErrors.city && !newForm.city && (
                        <p className="text-danger">{validationErrors.city}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-5 col-lg-5">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        id="state"
                        className="form-control"
                        placeholder="State / Province*"
                        required="Please fill input"
                        value={
                          newForm.state === undefined
                            ? user.state
                            : newForm.state
                        }
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            state: e.target.value,
                          })
                        }
                      />
                      <label>State / Province *</label>
                      {validationErrors.state && !newForm.state && (
                        <p className="text-danger">{validationErrors.state}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-12">
                    <div className="form-group mb-4">
                      <textarea
                        className="form-control h-auto"
                        rows={4}
                        required
                        id="address"
                        value={newForm.address}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            address: e.target.value,
                          })
                        }
                      />
                      <label className="foraddrss">Address *</label>
                      {validationErrors.address && !newForm.address && (
                        <p className="text-danger">
                          {validationErrors.address}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-4">
                      <select
                        type="text"
                        id="vehicle_owned"
                        className="form-control"
                        placeholder="Vehicle owned*"
                        required
                        value={newForm.vehicle_owned}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            vehicle_owned: e.target.value,
                          })
                        }
                      >
                        {vehicles.map((item, index) => (
                          <option value={item.value} key={index}>
                            {" "}
                            {item.label}{" "}
                          </option>
                        ))}
                      </select>
                      <label>Vehicle owned*</label>
                      {validationErrors.vehicle_owned && !newForm.vehicle_owned && (
                        <p className="text-danger">
                          {validationErrors.vehicle_owned}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        id="vin"
                        className="form-control"
                        placeholder="VIN/Chassis Number"
                        required
                        value={newForm.vin}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            vin: e.target.value,
                          })
                        }
                      />
                      <label>VIN/Chassis no</label>
                      {validationErrors.vin && !newForm.vin && (
                        <p className="text-danger">{validationErrors.vin}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group ">
                      <input
                        type="text"
                        id="car_reg_no"
                        className="form-control"
                        placeholder="Car registration number*"
                        required
                        value={newForm.car_reg_no}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            car_reg_no: e.target.value,
                          })
                        }
                      />
                      <label>Car registration number*</label>
                      {validationErrors.car_reg_no && !newForm.car_reg_no && (
                        <p className="text-danger">
                          {validationErrors.car_reg_no}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-4 my-3">
                    <h6>RC Upload</h6>
                  </div>
                  <div className="col-sm-4 col-lg-4 my-3 ">
                    <label
                      className="btn-upload"
                      style={{ display: "inline-block" }}
                    >
                      RC Front Side Upload*
                      <input
                        className=""
                        type="file"
                        id="rc_frontside_file"
                        onChange={(e) =>
                          handleFileUpload("rc_frontside_file", e)
                        }
                      />
                    </label>
                    {newForm.rc_frontside_file && (
                      <p className="text-success">
                        Image added successfully!{" "}
                        <span role="img" aria-label="checkmark">
                          ✅
                        </span>
                      </p>
                    )}
                    {validationErrors.rc_frontside_file && !newForm.rc_frontside_file && (
                      <p className="text-danger">
                        {validationErrors.rc_frontside_file}
                      </p>
                    )}
                  </div>

                  <div className="col-sm-4 col-lg-4 my-3">
                    <label
                      className="btn-upload"
                      style={{ display: "inline-block" }}
                    >
                      RC Back Side Upload*
                      <input
                        type="file"
                        id="rc_backside_file"
                        onChange={(e) =>
                          handleFileUpload("rc_backside_file", e)
                        }
                      />
                    </label>
                    {newForm.rc_backside_file &&   (
                      <p className="text-success">
                        Image added successfully!{" "}
                        <span role="img" aria-label="checkmark">
                          ✅
                        </span>
                      </p>
                    )}
                    {validationErrors.rc_backside_file && !newForm.rc_backside_file && (
                      <p className="text-danger">
                        {validationErrors.rc_backside_file}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        id="drive_no"
                        className="form-control"
                        placeholder="Driving license number"
                        required
                        value={newForm.drive_no}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            drive_no: e.target.value,
                          })
                        }
                      />
                      <label>Driving license number</label>
                      {validationErrors.drive_no && !newForm.drive_no && (
                        <p className="text-danger">
                          {validationErrors.drive_no}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-4">
                      <select
                        className="form-control"
                        placeholder="Guest accompanying*"
                        required=""
                        value={guestForm}
                        onChange={(e) => {
                          const selectedValue = Number(e.target.value);
                          setGuestForm(selectedValue);
                          setGuestDetails(
                            Array.from({ length: selectedValue }, () => ({}))
                          );
                          setOpenModal(selectedValue > 0);
                        }}
                      >
                        <option value="">None</option>
                        {[...Array(maxGuestAllowed)].map((_, index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>

                      <label>Guest accompanying*</label>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-4">
                      <select
                        className="form-control"
                        placeholder="Fully vaccinated*"
                        required
                        value={newForm.vaccinated}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            vaccinated: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>

                      <label>Fully vaccinated*</label>
                    </div>
                  </div>
                 
                  <div className="col-sm-4 col-lg-4 my-3 ">
                  <h6  >Driving License Upload</h6>
                </div>
                <div className="col-sm-4 col-lg-4 my-3 ">
                  <label  className="btn-upload"
                      style={{ display: "inline-block" }}>
                    Upload Driving License Front Side*
                    <input
                      type="file"
                      onChange={(e) => handleFileUpload("license_file", e)}
                    />
                  </label>
                  {newForm.license_file && (
                    <p className="text-success">
                      License added successfully!
                      <span role="img" aria-label="checkmark">
                        ✅
                      </span>
                    </p>
                  )}
                  {validationErrors.license_file && !newForm.license_file && (
                    <p className="text-danger">
                      {validationErrors.license_file}
                    </p>
                  )}
                </div>
                <div className="col-sm-4 col-lg-4 my-3 ">
                  <label  className="btn-upload"
                      style={{ display: "inline-block" }}>
                    Upload Driving License Back side*
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFileUpload("license_backside_file", e)
                      }
                    />
                  </label>
                  {newForm.license_backside_file && (
                    <p className="text-success">
                      License added successfully!
                      <span role="img" aria-label="checkmark">
                        ✅
                      </span>
                    </p>
                  )}
                  {validationErrors.license_backside_file && !newForm.license_backside_file && (
                    <p className="text-danger">
                      {validationErrors.license_backside_file}
                    </p>
                  )}
                
               </div>
                </div>

                <div className="form-row">
                  <div className="col-sm-4 col-lg-4">
                    <h4 className="genderheading">GENDER</h4>
                    <div className="d-flex mb-4">
                      <div className="checkboxmain mr-sm-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Malebx"
                          required
                          checked={newForm.gender === "Male"}
                          value="Male"
                          onChange={(e) =>
                            setNewForm({
                              ...newForm,
                              gender: e.target.value,
                            })
                          }
                        />
                        <label className="form-check-label" htmlFor="Malebx">
                          Male
                        </label>
                      </div>
                      <div className="checkboxmain mr-sm-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Femalebx"
                          required
                          checked={newForm.gender === "Female"}
                          value="Female"
                          onChange={(e) =>
                            setNewForm({
                              ...newForm,
                              gender: e.target.value,
                            })
                          }
                        />
                        <label className="form-check-label" htmlFor="Femalebx">
                          Female
                        </label>
                      </div>
                      <div className="checkboxmain">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Otherbx"
                          required
                          checked={newForm.gender === "Other"}
                          value="Other"
                          onChange={(e) =>
                            setNewForm({
                              ...newForm,
                              gender: e.target.value,
                            })
                          }
                        />
                        <label className="form-check-label" htmlFor="Otherbx">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4 col-lg-4">
                    <div class="form-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ex:- https://www.instagram.com/"
                        value={newForm.insta_url}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            insta_url: e.target.value,
                          })
                        }
                      />
                      <label>Instagram profile</label>
                      <span>ex:- https://www.instagram.com/</span>
                    </div>
                  </div>

                  <div class="col-sm-4 col-lg-4">
                    <div class="form-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ex:- https://www.facebook.com/"
                        value={newForm.facebook_url}
                        onChange={(e) =>
                          setNewForm({
                            ...newForm,
                            facebook_url: e.target.value,
                          })
                        }
                      />
                      <label>Facebook profile</label>
                      <span>ex:- https://www.facebook.com/</span>
                    </div>
                  </div>
                </div>

                <div className="form-row mb-5">
                  <div className="col-sm-12">
                    <div className="checkboxmain colorchng mr-sm-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="interestedbx"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="interestedbx"
                      >
                        I am interested in {eventData?.name}.
                      </label>
                    </div>
                    <div className="checkboxmain colorchng">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="termsbx"
                        value={termCondition}
                        onChange={() => setTermCondition(!termCondition)}
                      />
                      <label className="form-check-label" htmlFor="termsbx">
                        I accept the{" "}
                        <strong>
                          <u>
                            {" "}
                            <Link to="/term-condition">
                              terms and conditions
                            </Link>
                          </u>
                        </strong>
                        .
                      </label>
                      {validationErrors.termCondition &&  !termCondition && (
                        <p className="text-danger">
                          {"**"+  validationErrors.termCondition}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <Button
                    onClick={handleSubmit}
                    className="btn btn-primary widthincres"
                    // disabled={!termCondition  }
                  >
                    Next
                  </Button>
                </div>
                <div className="formheading text-center mt-5">
                  <h2>
                    Having trouble signing in? Write to us{" "}
                    <strong>
                      <a href="#">
                        <u>info@hyundaiexplorers.com</u>
                      </a>
                    </strong>
                  </h2>
                </div>
                {/* Copyright */}
              </form>
              {/* Form END */}
            </div>
          </div>
        </div>
      </div>

      {/* login modal */}
      {guestForm && (
        <section class="loginformbox pt-5 mt-5 pb-5">
          <div className="container">
            <div className="row justify-content-center align-items-center m-auto ">
              <div className="col-sm-12">
                <div className="bg-mode shadow rounded-3 overflow-hidden">
                  <Modal
                    show={openModal}
                    onHide={() => setOpenModal(false)}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                  >
                    <Modal.Body>
                      <div className="p-3 p-sm-4 p-md-5">
                        <button
                          className="close"
                          onClick={() => {
                            setOpenModal(false);
                            setGuestForm(0);
                          }}
                        >
                          <span>&times;</span>
                        </button>
                        <h2>Guest Form</h2>
                        <Form className="mt-2 text-left">
                          {guestDetails.map((item, index) => (
                            <div key={index} className="form py-4">
                              <h4>Guest {index + 1}</h4>
                              <Form.Group
                                controlId={`name${index}`}
                                className="mb-4"
                              >
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                  type="Name"
                                  placeholder="Name"
                                  required
                                  onChange={(e) =>
                                    handleGuestInputChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                controlId={`age${index}`}
                                className="mb-4"
                              >
                                <Form.Label>Age</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Age"
                                  required
                                  onChange={(e) =>
                                    handleGuestInputChange(
                                      index,
                                      "age",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                controlId={`vaccinated${index}`}
                                className="mb-4"
                              >
                                <Form.Label>Fully vaccinated</Form.Label>
                                <Form.Select
                                  className="form-control"
                                  placeholder="Guest accompanying*"
                                  required
                                  onChange={(e) =>
                                    handleGuestInputChange(
                                      index,
                                      "vaccinated",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select an option</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                          ))}
                          {/* Inside your modal */}

                          {/* Inside your modal */}
                          <Form.Group
                            controlId="modalActions"
                            className="mb-4   justify-content-end  "
                          >
                            <Button
                              onClick={(e) => {
                                setOpenModal(false);
                                setGuestForm(0);
                              }}
                            >
                              Close
                            </Button>{" "}
                            <Button
                              variant="primary"
                              onClick={(e) => setOpenModal(false)}
                            >
                              Save
                            </Button>
                          </Form.Group>
                        </Form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

export default RegistrationCarnivalPage;
