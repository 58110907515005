import React, { useEffect } from "react";
 
const AboutPage = () => {
  useEffect(() => {
    document.title = "About-us | HyundaiExplorers";
  }, []);
  return (
    <>
      <section
        className="innerbannerHeader"
        style={{ background: "url(/assets/images/aboutus1.webp)" }}
      >
        <div className="container">
          <h1 className="text-left">#HyundaiExplorers</h1>
          <h2 className="text-left">Live the Hyundai SUV life</h2>
        </div>
        </section>
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="secTitle mb-4">
              <h2>
                The quest for the unknown. The spirit of exploration. The spirit
                of discovery.
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 mb-4">
                <div className="welcom-section">
                  <p>
                  When it comes to adventure and celebrations, we are your obvious choice. Hyundai Explorers is Hyundai owners' community. It's a unique ensemble of curious and passionate individuals united by the pride of being a Hyundai owner.
                  </p>
                  <p>
                  The Hyundai Explorers team brings you specially curated unforgettable escapades to some of the country's most enchanting and scintillating locations. Be it an Adventure or a Carnival, you create cherished memories with us.
                  </p>
                  <p>
                  While Hyundai SUV-only Adventure, pumps you with thrill and exhilaration, Carnival celebrates the ever-growing Hyundai family at a larger-than-life scale.
                  </p>
                  <p>
                  So what are you waiting for? Our next extravaganza is arriving soon. See you at the starting line.
                  </p>
                  
                    <p>The quest for the unknown.</p>
                    <p>The spirit of exploration.</p>
                    <p>The spirit of discovery.</p>
                    <p>#HyundaiExplorers</p>
                    <p>#HyundaiIndia</p>
                  
                  {/* <ul className="site-list">
                    <li>The quest for the unknown.</li>
                    <li>The spirit of exploration.</li>
                    <li>The spirit of discovery.</li>
                    <li>#HyundaiExplorers</li>
                    <li>#HyundaiIndia</li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-4">
                <div className="img-colarge2">
                  <div className="colarge-2">
                    <img
                       src="/assets/images/aboutus02.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
     
    </>
  );
};

export default AboutPage;
