import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { baseUrl, getSingleEventsApi } from "../APIs/apis";

// Import lazy-loaded BannerComponent
const LazyBannerComponent = lazy(() => import("../Components/BannerComponent"));

const EventDetailPage = () => {
   
  const [showTab, setShowTab] = useState(0);
  const slugName = useParams().slug; // Get slug from URL params
  const [filterEvent, setEventData] = useState([]);
  const [bannerInfo, setBannerInfo] = useState({
    imageUrl: filterEvent?.experiance_banner_image || "", // Provide a default image URL
    bannerTitle: filterEvent?.experiance_banner_title || "", // Provide a default banner title
  });

  const fetchEventData = async () => {
    try {
      const response = await getSingleEventsApi(slugName);

      setEventData(response.event);
    } catch (error) {
      console.error("Error fetching event data:", error);
      // Handle errors, e.g., show an error message
    }
  };
   
  useEffect(() => {
  
      document.title = slugName + " | HyundaiExplorers";
    
    
    // Define a function to fetch event data
    // Call the fetchEventData function when the component mounts
    fetchEventData();

    // Cleanup function
    return () => {
      // Any cleanup code if needed
    };
  }, [slugName]); // Run the effect whenever the slugName changes
 
  // get category name
  const categoryName = filterEvent && filterEvent?.category;

  useEffect(() => {
    // Update bannerInfo based on filterEvent
    setBannerInfo({
      imageUrl: filterEvent?.experiance_banner_image || "",
      bannerTitle: filterEvent?.experiance_banner_title || "",
    });
  }, [filterEvent]);


 
  const handleTabClick = (tabIndex) => {
    setShowTab(tabIndex);

    // Update bannerInfo based on the clicked tab
    switch (tabIndex) {
      case 0:
        setBannerInfo({
          imageUrl: filterEvent?.experiance_banner_image || "",
          bannerTitle: filterEvent?.experiance_banner_title || "",
        });
        break;
      case 1:
        setBannerInfo({
          imageUrl: filterEvent?.itinerary_banner_image || "",
          bannerTitle: filterEvent?.itinerary_banner_title || "",
        });
        break;
      case 2:
        setBannerInfo({
          imageUrl: filterEvent?.accommondation_banner_image || "",
          bannerTitle: filterEvent?.accommondation_banner_title || "",
        });
        break;
      case 3:
        setBannerInfo({
          imageUrl: filterEvent?.useful_banner_image || "",
          bannerTitle: filterEvent?.useful_banner_title || "",
        });
        break;
      case 4:
        setBannerInfo({
          imageUrl: filterEvent?.faq_banner_image || "",
          bannerTitle: filterEvent?.faq_banner_title || "",
        });
        break;
      default:
        setBannerInfo({
          imageUrl: filterEvent?.experiance_banner_image || "",
          bannerTitle: filterEvent?.experiance_banner_title || "",
        });
        break;
    }
  };

  // download brochure
  // get event brochure
  const event_file = filterEvent && baseUrl + filterEvent?.brochure_file;
 
  const downloadBrochureFile = (fileUrl) => {
    // Assuming 'fileUrl' is the URL to your ODF file
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "brochure.pdf"; // Change the filename as needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <main role="main">
      {filterEvent && bannerInfo.imageUrl && (
        <Suspense fallback={<div>Loading...</div>}>
        
          <LazyBannerComponent
            imageUrl={bannerInfo.imageUrl}
            bannerTitle={bannerInfo.bannerTitle}
          />
        </Suspense>
      )}

      <section className="bread-crumb">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-12 col-sm p-0">
              <a
                className={`breadcrumbbox ${showTab === 0 && "grayband"}`}
                href="#"
                onClick={() => handleTabClick(0)}
              >
                Experience
              </a>
            </div>
            <div className="col-12 col-sm p-0">
              <a
                className={`breadcrumbbox ${showTab === 1 && "grayband"}`}
                href="#"
                onClick={() => handleTabClick(1)}
              >
                Itinerary
              </a>
            </div>

            {categoryName !== "Carnival" && filterEvent?.accommondation?.length !== 0 && (
              <div className="col-12 col-sm p-0">
                <a
                  className={`breadcrumbbox ${showTab === 2 && "grayband"}`}
                  href="#"
                  onClick={() => handleTabClick(2)}
                >
                  Accommodation
                </a>
              </div>
            )}

            <div className="col-12 col-sm p-0">
              <a
                className={`breadcrumbbox ${showTab === 3 && "grayband"}`}
                href="#"
                onClick={() => handleTabClick(3)}
              >
                Useful Information
              </a>
            </div>
            <div className="col-12 col-sm p-0">
              <a
                className={`breadcrumbbox ${showTab === 4 && "grayband"}`}
                href="#"
                onClick={() => handleTabClick(4)}
              >
                FAQ's
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* -----default tab  experiance */}
      {showTab === 0 && (
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              {categoryName !== "Carnival" && (
                <div className="col-sm-12 col-md-auto ml-auto">
                 
                  <button
                    className="btn btn-primary"
                    onClick={() => downloadBrochureFile(event_file)}
                  >
                    Download Brochure
                  </button>
                </div>
              )}
              <div className="col-sm-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: filterEvent && filterEvent.about,
                  }}
                />
              </div>
            </div>
          </div>

          <br />
          <br />
         
          <div className="container">
            <div className="row">
              <div className="col-sm-3" >
                <div className="locationtimebox">
                  <div className="btn_div btn-new_div"   >
                    Date &amp; Time
                  </div>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: filterEvent?.dateLabel,
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="locationtimebox">
                  <div className="btn_div btn-new_div"  >
                    Location
                  </div>
                  <h4>{filterEvent?.location} </h4>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="locationtimebox">
                  <div className="btn_div btn-new_div"   >
                    Ticket Price
                  </div>
                  {filterEvent.category === "Carnival" ? 
                  <h4>INR {filterEvent?.price} per car</h4> :
                  <h4>INR {filterEvent?.price} for 2 participants</h4>
                }
                </div>
              </div>
              <div className="col-sm-3">
                <div className="locationtimebox">
                  <div className="btn_div btn-new_div"   >
                    Remaining Seats
                  </div>
                  <h4>
                    {filterEvent?.event_status === "close" || filterEvent?.event_status === "archive"
                      ? "NA"
                      : filterEvent?.slots}{" "}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/*  itinery */}
      {showTab === 1 && (
        <section className="pt-5 pb-5">
          <div className="container">
           

            {filterEvent?.itinerary &&
              filterEvent?.itinerary.map((item, index) => (
                <div key={index} className="outershadow">
                  <div className="row m-0 p-0">
                    {index % 2 === 0 ? (
                      <>  
                        <div className="col-sm-6 m-0 p-0">
                          <figure className="Leftthumb"
                            style={{
                              backgroundImage: `url(${baseUrl + item.image})`,
                            }}
                          ></figure>
                        </div>
                        <div className="col-sm-6 m-0 p-0">
                          <div className="RightText">
                            <h3>{item.day}</h3>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.details }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-sm-6 m-0 p-0 order-2 order-sm-0">
                          <div className="LeftText">
                            <h3>{item.day}</h3>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.details }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 m-0 p-0 order-1 order-sm-0">
                          <figure className="Rightthumb"
                            style={{
                              backgroundImage: `url(${baseUrl + item.image})`,
                            }}
                          ></figure>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <br />
          <br />
        </section>
      )}

      {/* third tab accomandation */}

      {showTab === 2 && filterEvent?.accommondation.length !== 0 && (
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="secTitle mb-4">
              <h2>Accommodation</h2>
            </div>
            {filterEvent?.accommondation &&
              filterEvent?.accommondation.map((item, index) => (
                <div key={index} className="outershadow">
                  <div className="row m-0 p-0">
                    {index % 2 === 0 ? (
                      <>
                        <div className="col-sm-6 m-0 p-0">
                          <figure className="Leftthumb"
                            style={{
                              backgroundImage: `url(${baseUrl + item.image})`,
                            }}
                          ></figure>
                        </div>
                        <div className="col-sm-6 m-0 p-0">
                          <div className="RightText">
                            <h3>{item.title}</h3>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.details }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-sm-6 m-0 p-0">
                          <div className="LeftText">
                            <h3>{item.title}</h3>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.details }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 m-0 p-0">
                          <figure className="Rightthumb imgcover "
                            style={{
                              backgroundImage: `url(${baseUrl + item.image})`,
                            }}
                          ></figure>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <br />
          <br />
        </section>
      )}

      {/*  use full information */}
      {showTab === 3 && (
        <section className="faqsbx pt-5 pb-5">
          <div className="container">
            <Accordion defaultActiveKey="0" className="">
              {filterEvent &&
                filterEvent?.usefulInformation.map((item, index) => (
                  <Accordion.Item eventKey={index?.toString()} key={index}>
                    <Accordion.Header> {item.title} </Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: item.detail }} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </section>
      )}

      {/* faq  tab */}

      {showTab === 4 && (
        <section className="faqsbx pt-5 pb-5">
          <div className="container">
         
            <Accordion defaultActiveKey="0" className="">
              {filterEvent &&
                filterEvent?.faq.map((item, index) => (
                  <Accordion.Item eventKey={index?.toString()} key={index}>
                    <Accordion.Header> {item.question} </Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>{" "}
        </section>
      )}

      <div className="container">
        <div className="row">
          <div className="col-xl-3 mx-auto text-center mb-4">
            {filterEvent?.event_status === "active" &&
            filterEvent?.slots > 0 ? (
              // if carnival event then redirect to carnival register page
              <Link
                className="btn btn-primary w-100"
                to={ categoryName==="Carnival" && filterEvent.payment_gateway_url !== null ?  `/carnival-register/${slugName}`  : `/register/${slugName}`}
              >
                Book Now
              </Link>
            ) : (
              <Link
                className="btn btn-primary w-100"
                to={`/event/${filterEvent?.slug}`}
              >
                Registration Closed
              </Link>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default EventDetailPage;
