import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl, getLiveTestimonialAPI } from "../APIs/apis";

const HomeTestimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [testimonial, setTestimonial] = useState([]);
  const nextSlide = () => {
    setCurrentSlide(
      currentSlide === testimonial.length - 1 ? 0 : currentSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? testimonial.length - 1 : currentSlide - 1
    );
  };

  const getTestimonialData = async () => {
    try {
      const res = await getLiveTestimonialAPI();
      const filter = res.data.filter((item) => item.show_home == "Yes");
      setTestimonial(filter);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    getTestimonialData();
  }, []);
  return (
    <section className="testimonialBG pt-5 pb-5">
      <div className="container">
        <div className="secTitle pb-5">
          <h2>CUSTOMER TESTIMONIALS</h2>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="carouseltextimonial">
              <div
                id="testimonialbx"
                className="carousel slide"
                data-ride="carousel"
              >
                {/* Slides */}
                <div className="carousel-inner  ">
                  {testimonial &&
                    testimonial?.map((testimonial, index) => (
                      <div
                        key={index}
                        className={
                          currentSlide === index
                            ? " carousel-item active"
                            : "carousel-item"
                        }
                      >
                        <div className="testimonial-item">
                          <div className="testimonials_item">
                            <div className="row m-0">
                              <div className="col-sm-6 p-0">
                                <div className="card-testimonial_profile">
                                  <img
                                    src={baseUrl + testimonial.testimonialFile}
                                    alt=""
                                    className="card-testimonial_image"
                                    loading="lazy"
                                  />
                                </div> 
                              </div>
                              <div className="col-sm-6 p-0">
                                <div className="card-testimonial_description">
                                  <h3>{testimonial.name}</h3>
                                  <h4>{testimonial.companyName}</h4>
                                  <p>
                                    <i
                                      className="fa fa-quote-left h4"
                                      aria-hidden="true"
                                    />
                                    { testimonial.description.length > 300 
    ? testimonial.description.substring(0, 300) + "..." 
    : testimonial.description}
                                    <i
                                      className="fa fa-quote-right h4"
                                      aria-hidden="true"
                                    />
                                  </p>
                                  <Link
                                    to="/testimonials"
                                    className="btn viewmorebtn"
                                  >
                                    View More
                                    <i
                                      className="fa fa-angle-double-right"
                                      aria-hidden="true"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* Controls */}
                {testimonial.length > 1 && (
                  <>
                    <div
                      className="carousel-control-prev"
                      // to="#testimonialbx"

                      data-slide="prev"
                      onClick={prevSlide}
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      >
                        <i className="fa fa-chevron-left" aria-hidden="true" />
                      </span>
                      <span className="sr-only">Previous</span>
                    </div>
                    <div
                      className="carousel-control-next"
                      // to="#testimonialbx"

                      data-slide="next"
                      onClick={nextSlide}
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      >
                        <i className="fa fa-chevron-right" aria-hidden="true" />
                      </span>
                      <span className="sr-only">Next</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTestimonial;
