import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../Components/Carousel';
import AboutSection from '../Components/AboutSection'; // Import the AboutSection component
import HomeTestimonial from '../Components/HomeTestimonial';
import HomeEvent from '../Components/HomeEvent';
import HomeGallery from '../Components/HomeGallery';
import HomeSocial from '../Components/HomeSocial';

const Home = () => {
  
  return (
    < >
      <Carousel  />
      <AboutSection /> {/* Use the AboutSection component here */}
      <HomeTestimonial/>
      
      <HomeEvent/>
      <HomeGallery/>
      <HomeSocial/>
    </ >
  );
};

export default Home;
