import React, { useEffect, useState } from 'react';
import GalleryComponent from "../Components/GalleryComponent";
import GalleryFormComponent from "../Components/GalleryFormComponent";
import { Link } from 'react-router-dom';
import { showGalleryAPI } from "../APIs/apis";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState(null);
  const [activeYear, setActiveYear] = useState("All");

  useEffect(() => {
    document.title = "Gallery | HyundaiExplorers";
    const fetchGalleryData = async () => {
      try {
        const response = await showGalleryAPI();
         
        setGalleryData(response);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      }
    };

    fetchGalleryData();
  }, []);

  const handleYearChange = (year) => {
    setActiveYear(year);
  };

  let filteredEvents = [];
  if (galleryData) {
    filteredEvents = activeYear === "All" ? 
      galleryData.archivedEventsWithImages : 
      galleryData.archivedEventsWithImages.filter(event => event.year === activeYear);
    
    // Limiting to maximum 6 events
    //filteredEvents = filteredEvents.slice(0, 6);
  }

  return (
    <section className="innergallerybx py-5 pb-5">
      <div className="container-fluid pt-5 px-sm-5">
        <div className="row pt-3">
          {/* tab start */}
          <div className="col-sm-12 col-lg-12">
          <div className="tabpillsbox mt-0">
            <ul
                    className="nav nav-pills justify-content-center mb-5"
                    id="pills-tab"
                    role="tablist"
                >
                    {galleryData && galleryData.year.map((year, index) => (
                        <li className="nav-item" key={index}>
                            <Link
                                className={`nav-link ${activeYear === year ? "active" : ""}`}
                                onClick={() => handleYearChange(year)}
                            >
                                {year}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
          </div>
          <div className="col-sm-12 col-lg-8">            
            {/* Render content based on active year */}
            <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="allevents"
                        role="tabpanel"
                        aria-labelledby="allevents-tab"
                    >
                  <div className="row">
                            <GalleryComponent allGallery={filteredEvents} />
                        </div>
                </div>
              
            </div>
          </div>
          {/* tab end */}
        

          {/*---------------------gallery form-------------------------------- */}

          <GalleryFormComponent />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
