import React, { useState } from "react";
import GalleryModal from "./GalleryModal";
import { baseUrl } from "../APIs/apis";


const GalleryComponent = ({ allGallery }) => {
    
    const [open, setOpen] = useState(false);
    const [selectedGalleryItem, setSelectedGalleryItem] = useState(null); // Moved useState call to the top level

    // Ensure allGallery is an array before calling map function
    if (!Array.isArray(allGallery)) {
        return <div>Loading...</div>; // or any other loading indicator
    }

    const handleGalleryItemClick = (gallery) => {
        setSelectedGalleryItem(gallery?.images);
        if (gallery?.images.length > 0)
            setOpen(true);
    };

    return (
        <>
            {allGallery.map((gallery, index) => (
                <div className="col-sm-6 col-lg-4 mb-4" key={index}>
                    <div className="eventsmainbx">
                        <figure>
                            <img
                                src={ baseUrl+gallery.thumbnail}
                                className="img-fluid"
                                alt={gallery.eventName}
                                loading="lazy"
                            />
                        </figure>

                        <figcaption className="gallerybox text-center">
                            <h2>{gallery?.eventName} </h2>
                            <a
                                className="gallerybtn"
                                onClick={() => handleGalleryItemClick(gallery)}
                            >
                                <span>View Gallery</span>
                            </a>
                        </figcaption>
                    </div>
                </div>
            ))}
            {open && (
                <GalleryModal
                    open={open}
                    setOpen={setOpen}
                    galleryData={selectedGalleryItem}
                />
            )}
        </>
    );
};

export default GalleryComponent;
