import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { baseUrl } from "../APIs/apis";

const GalleryModal = ({ open, setOpen, galleryData }) => {
  const slides = galleryData
    ? galleryData?.map((image) => ({
      src:baseUrl+ image.imageUrl,
      title: image.title,
    }))
    : [];

  return (
    <div style={{ width: "600px" }}>
      <Lightbox
        open={open}
        close={() => {
          setOpen(false);
        }}
        plugins={[Thumbnails, Captions]}
        captions={{
          descriptionTextAlign: 'center', // Set the text alignment to center
        }}
        slides={slides}
      />
    </div>
  );
};

export default GalleryModal;
