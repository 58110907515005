import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Layout = ({ children }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }; 
  return (
    <main>
      {/* header componet */}
      <Header />
      <main role="main" className="main-container" style={{minHeight:'450px'}}>
        {children}
      </main>
      {/* footer component */}

      {/* right side home icon to go home page */}
      <Link   className="homefixbtn" onClick={scrollToTop}>
        <i className="fa-solid fa-chevron-up"></i>
      </Link>

      <Footer />
    </main> 
  );
};

export default Layout;
