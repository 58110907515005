import React from "react";
import { Link, useNavigate } from "react-router-dom";

const SuccessPage = () => {
  return (
    <section className="loginformbox pt-5 mt-5 pb-5">
      <div className="container">
        <div className="row justify-content-center align-items-center m-auto">
          <div className="col-sm-6">
            <div className="bg-mode shadow rounded-3 overflow-hidden">
              <div className="p-3 p-sm-4 p-md-4 text-center">
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <div className="square-80 text-light bg-success">
                    <i className="fa fa-check h1" aria-hidden="true" />
                  </div>
                </div>
                <h1 className="text-primary mb-2 h4">
                  Registered Successfully!
                </h1>
                <p className="mb-3">
                  Welcome aboard! your journey with #HyundaiExplorers begines!
                </p>
                <p className="mb-3">
                  you can now check your mailbox, verify your email and choose a
                  password to log in future.
                </p>
                <p className="mb-0">
                  <strong>Happy exploring!</strong>
                </p>
                <div className="text-center mt-4 mb-1">
                  <Link to="/" className="btn btn-primary">
                    Back to home
                    <i
                      className="fa fa-angle-double-right ml-2"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessPage;
