import React from "react";
 
import FansOfHyundaiComponent from "../Components/FansOfHyundaiComponent";

const FansOfHyundai = () => {
  const data = [1, 2, 3, 4, 5, 6];
  return (
    <section className="mt-5 py-5">
      <div className="container pt-3">
        <div className="secTitle pb-4 mb-3">
          <h2>#FANS OF HYUNDAI</h2>
        </div>
        <div className="row justify-content-center">
          {data &&
            data.map((item, index) => (
              <FansOfHyundaiComponent key={index} data={item} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default FansOfHyundai