// import React from 'react'
// import { baseUrl } from '../APIs/apis'

// const TestimonialPageComponent = ({data}) => {
//   return (
//     <div className="col-sm-4 col-lg-4 col-xl-4 mb-4">
//     <div className="testimonialInner-box">
//       <div> 
//         <div className="image">
//           <img alt= {data.name} src={baseUrl + data.testimonialFile} loading="lazy" />
//         </div>
//         <div className="lower-content">
//           <ul className="post-info">
//             <li>{data.companyName}</li>
//           </ul>
//           <h4>{data.name}</h4>
//           <p>
//             <i className="fa fa-quote-left" aria-hidden="true" />
//             {data.description}
//             <i className="fa fa-quote-right" aria-hidden="true" />
//           </p>
           
//         </div>
//       </div>
//     </div>
//   </div>
//   )
// }

// export default TestimonialPageComponent

import React, { useState } from "react";
import { baseUrl } from "../APIs/apis";

const TestimonialPageComponent = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  const truncatedDescription =
    data.description.length > 300
      ? `${data.description.substring(0, 300)}... `
      : data.description;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="col-sm-4 col-lg-4 col-xl-4 mb-4">
      <div className="testimonialInner-box" onMouseLeave={handleMouseLeave}>
        <div>
          <div className="image">
            <img
              alt={data.name}
              src={`${baseUrl}${data.testimonialFile}`}
              loading="lazy"
            />
          </div>
          <div className="lower-content">
            <ul className="post-info">
              <li>{data.companyName}</li>
            </ul>
            <h4>{data.name}</h4>
            <p>
              <i className="fa fa-quote-left" aria-hidden="true" />
              {isHovered ? data.description : truncatedDescription}
              {data.description.length > 300 && !isHovered && (
                <span
                  className="read-more"
                  onClick={handleMouseEnter}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Read More
                </span>
              )}
              <i className="fa fa-quote-right" aria-hidden="true" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialPageComponent;