import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header>
  <nav className="navbar navbar-expand-md menu-bar fixed-top">
    <div className="container p-0">
      <Link className="navbar-brand" to="https://www.hyundai.com/in/en" target="_blank">
        <img src="/assets/images/ico-logo.png" className="img-fluid" alt="" />
        {/* <img src="assets/images/hyundaimainlogo.png" class="img-fluid" alt=""> */}
      </Link>
      {/* <Link class="btn order-sm-last btnheader" to="#">Register</Link> */}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about-hyundai-explorers">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/testimonials">
              Testimonial
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Explore
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/adventure-drive">
                Adventure
              </Link>
              <div className="dropdown-divider" />
              <Link className="dropdown-item" to="/carnival-drive">
                Carnival
              </Link>
              <div className="dropdown-divider" />
              
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/gallery">
              Gallery
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact-us">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

  )
}

export default Header