import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footermain ">
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <div className="footerlogo">
            <Link to="/">
              <img src="/assets/images/footerlogowhite.png" title="logo" alt='' />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="footerlink">
            <Link to="/contact-us">Contact us </Link>
            <Link to="/term-condition">T&amp;C</Link>
            <Link to="/faqs">FAQs</Link>
            <Link to="/legal-disclaimer">Legal disclaimer</Link>
            {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
          </div>
          <div className="enqiryfooter mt-2 text-left">
            <span className="d-block">
              © 2023 Hyundai Explorers. All rights reserved
            </span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="footersocial justify-content-sm-end">
            <Link to="https://www.instagram.com/hyundaiexplorers/"  target="_blank">
              <svg
                className="instagramicon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="24px"
                height="24px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 20 20"
              >
                <path
                  d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h14c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2zM9.984 15.523a5.539 5.539 0 0 0 5.538-5.539c0-.338-.043-.664-.103-.984H17v7.216a.69.69 0 0 1-.693.69H3.693a.69.69 0 0 1-.693-.69V9h1.549c-.061.32-.104.646-.104.984a5.54 5.54 0 0 0 5.539 5.539zM6.523 9.984a3.461 3.461 0 1 1 6.922 0a3.461 3.461 0 0 1-6.922 0zM16.307 6h-1.615A.694.694 0 0 1 14 5.308V3.691c0-.382.31-.691.691-.691h1.615c.384 0 .694.309.694.691v1.616c0 .381-.31.693-.693.693z"
                  fill="#999"
                />
              </svg>
            </Link>
            <Link to="https://www.facebook.com/hyundaiexplorers
"  target="_blank">
              <svg
                className="facebookicon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="24px"
                height="24px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                  fill="#999"
                />
              </svg>
            </Link>
            <Link to="https://www.youtube.com/playlist?list=PLu715x0E8GkIV8PD_I80hDXIKyHzjvVdh
"  target="_blank">
              <svg
                className="youtubeicon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="24px"
                height="24px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765c1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6l5.207 3.005l-5.212 2.995z"
                  fill="#999"
                />
              </svg>
            </Link>
          </div>
          <div className="copyright text-sm-right mt-2">
            <Link to="mailto:info@hyundaiexplorers.com">
              info@hyundaiexplorers.com
            </Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer