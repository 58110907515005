import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Banner from './SubComponent/Banner';
import { gethomeCarouselAPI } from "../APIs/apis";
import { baseUrl } from "../APIs/apis";

const Carousel = ({ targetDate }) => {
    const [carouselData, setCarouselData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        document.title = "Home | HyundaiExplorers";
        // Fetch carousel data from API
        gethomeCarouselAPI()
            .then(response => {
                // Sort carousel data by priority
                const sortedData = response.data.sort((a, b) => a.priority - b.priority);
                setCarouselData(sortedData);
            })
            .catch(error => console.error('Error fetching carousel data:', error));
    }, []);

    const goToPrevSlide = () => {
        const newIndex = (activeIndex === 0) ? (carouselData.length - 1) : (activeIndex - 1);
        setActiveIndex(newIndex);
    };

    const goToNextSlide = () => {
        const newIndex = (activeIndex === carouselData.length - 1) ? 0 : (activeIndex + 1);
        setActiveIndex(newIndex);
    };

    return (
        <section id="myCarousel" className="carousel carouselmain slide" data-ride="carousel">
            <div className="containerbox">
                <ol className="carousel-indicators">
                    {carouselData.map((item, index) => (
                        <li key={index} data-target="#myCarousel" data-slide-to={index} className={index === activeIndex ? "active" : ""} />
                    ))}
                </ol>
                <div className="carousel-inner">
                    {carouselData.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === activeIndex ? "active" : ""}`}>
                            <Banner
                                imageUrl={baseUrl + item.content}
                                type={item.type}
                                content={item.content}
                                priority={item.priority}
                                title={item.heading}
                                subtitle={item.subHeading}
                                date={item.dateTitle}
                                showCountDown={item.showCountDown}
                                startDate={item.startDate}
                                ctaButtons1={item.ctaButtons1}
                                ctaButtons2={item.ctaButtons2}
                                targetDate={targetDate}
                            />
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" onClick={goToPrevSlide} aria-label="Previous" style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <span className="carousel-control-prev-icon" aria-hidden="true">
                        <i className="fa fa-chevron-left" aria-hidden="true" />
                    </span>
                </button>
                <button className="carousel-control-next" onClick={goToNextSlide} aria-label="Next" style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <span className="carousel-control-next-icon" aria-hidden="true">
                        <i className="fa fa-chevron-right" aria-hidden="true" />
                    </span>
                </button>
            </div>
        </section>
    );
};

export default Carousel;
