import React, { useState } from "react";
import { createNewInterestedUser } from "../APIs/apis";
import { Link, useNavigate, useParams } from "react-router-dom";
const GalleryFormComponent = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    pincode: "",
    countryCode: "+91",
    phoneNumber: "",
    acceptedTerms: false,
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && isNaN(value)) {
      return; // If value is not a number, do nothing
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    // Remove error for the corresponding field when user starts filling it
    const updatedErrors = { ...errors };
    delete updatedErrors[name];
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form data
    const validationErrors = {};
    if (!formData.fullName.trim()) {
      validationErrors.fullName = "Full Name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is invalid";
    }
    if (!formData.pincode.trim()) {
      validationErrors.pincode = "Pincode is required";
    } else if (isNaN(formData.pincode.trim())) {
      validationErrors.pincode = "Pincode must be a number";
    }
    if (!formData.phoneNumber.trim()) {
      validationErrors.phoneNumber = "Phone Number is required";
    }
    if (!formData.acceptedTerms) {
      validationErrors.acceptedTerms =
        "You must accept the terms and conditions";
    }

    if (Object.keys(validationErrors).length === 0) {
      // Proceed with form submission
      

      try {
        const res = await createNewInterestedUser(formData);
        setFormData({
          fullName: "",
          email: "",
          pincode: "",
          countryCode: "+91",
          phoneNumber: "",
          acceptedTerms: false,
        });
        navigate("/thank-you");
      } catch (error) {}
      // Reset form data
      
    } else {
      setErrors(validationErrors);
    }
  };
  return (
    <div className="col-sm-12 col-lg-4">
      <div className="galleryformbox">
        <div className="loginformbox">
          <div className="formcontrollerbx bg-mode shadow rounded-3 overflow-hidden p-4">
            <div className="innerheading text-center">
              <h2>Stay Updated</h2>
            </div>
            {/* Form START */}
            <form className="form mt-0 text-left" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="col-sm-12 col-lg-12">
                  <div className="form-group mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                    {errors.fullName && (
                      <p className="error">{errors.fullName}</p>
                    )}
                    <label>Full Name</label>
                  </div>
                  <div className="form-group mb-4">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="E-mail ID*"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                    <label>E-mail ID*</label>
                  </div>
                  <div className="form-group mb-4">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Pincode*"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleChange}
                      required
                    />
                    {errors.pincode && (
                      <p className="error">{errors.pincode}</p>
                    )}
                    <label>Pincode*</label>
                  </div>
                </div>
              </div>
              <div className="form-row mb-2">
                <div className="col-sm-3 col-lg-3">
                  <div className="form-group mb-4">
                    <select
                      className="form-control"
                      name="countryCode"
                      value={formData.countryCode}
                      onChange={handleChange}
                    >
                      <option value="+91">+91</option>
                      <option value="+92">+92</option>
                      <option value="+93">+93</option>
                      <option value="+94">+94</option>
                      <option value="+95">+95</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-9 col-lg-9">
                  <div className="form-group mb-4">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      pattern="[0-9]*"
                      required
                    />
                    {errors.phoneNumber && (
                      <p className="error">{errors.phoneNumber}</p>
                    )}
                    <label>Phone Number</label>
                  </div>
                </div>
              </div>
              <div className="form-row mb-4">
                <div className="col-sm-12">
                  <div className="checkboxmain colorchng mr-sm-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="interestedbx"
                      name="acceptedTerms"
                      checked={formData.acceptedTerms}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="interestedbx">
                      I accept the{" "}
                      <strong>
                        <u>
                          <a href="/term-condition">terms and conditions</a>
                        </u>
                      </strong>{" "}
                      as well as{" "} 
                      <strong>
                        <u>
                          <a href="/legal-disclaimer">privacy policy</a>
                        </u>
                      </strong>{". "}
                    </label>
                  </div>
                  {errors.acceptedTerms && (
                    <p className="error">{errors.acceptedTerms}</p>
                  )}
                </div>
              </div>
              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn btn-primary widthincres"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
              {/* Copyright */}
            </form>
            {/* Form END */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryFormComponent;
