import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CountdownTimer from "./CountDownTimer";

const Banner = ({
  imageUrl,
  type,
  title,
  subtitle,
  date,
  showCountDown,
  startDate,
  ctaButtons1,
  ctaButtons2,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (type === "video" && videoRef.current) {
      const video = videoRef.current;
      video.play().catch(error => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, [type]);

  const targetDate = new Date("2024-09-11");

  const renderCountDown = () => {
    if (showCountDown === "show") {
      // Parse the received startDate string
      const parsedStartDate = new Date(startDate);

      // Check if the parsedStartDate is a valid date
      if (!isNaN(parsedStartDate.getTime())) {
        return (
          <div className="countdownband">
            <div className="countdownbox">
              <div id="countdown">
                <CountdownTimer targetDate={parsedStartDate} />
              </div>
            </div>
          </div>
        );
      } else {
        console.error("Invalid startDate format:", startDate);
      }
    }
    return null;
  };

  const renderCTAButton = (cta) => {
    if (cta.isVisible) {
      return (
        <p>
          <Link className="btn btn-primary" to={cta.link} role="button">
            {cta.label}
          </Link>
        </p>
      );
    }
    return null;
  };

  return (
    <>
      {type === "image" ? (
        <img className="banner-slide" src={imageUrl} alt="Banner slide" loading="lazy" />
      ) : type === "video" ? (
        // Render video component
        <video ref={videoRef} width="640" height="360" autoPlay muted playsInline loop>
          <source src={imageUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : null}
      <div className="container">
        <div className="carousel-caption slide-inner text-left">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
          <p dangerouslySetInnerHTML={{ __html: date }} />
          {renderCountDown()}
          <div className="pt-3 text-left">
            {renderCTAButton(ctaButtons1)}
            {renderCTAButton(ctaButtons2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
