import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getActiveAllAdventureDataAPI } from "../../APIs/apis";
import { baseUrl } from "../../APIs/apis";

const AdventureSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [adventureEvents, setAdventureEvents] = useState([]);

  // Fetch adventure events from API
  useEffect(() => {
    const fetchAdventureEvents = async () => {
      try {
        const response = await getActiveAllAdventureDataAPI();
        //console.log(response.adventureEvents)
        setAdventureEvents(response.adventureEvents);
      } catch (error) {
        console.error("Error fetching adventure events:", error);
      }
    };

    fetchAdventureEvents();
  }, []);

  // Function to handle cycling through slides
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % adventureEvents.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (currentSlide - 1 + adventureEvents.length) % adventureEvents.length
    );
  };

  return (
    <div className="col-sm-6 col-md-6 mb-4">
      <div className="secTitle">
        <h2>Adventure</h2> 
      </div>

      <div className="happeningbox ">
        <div className="slides-container">
          {adventureEvents.map((event, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              <img
                src={`${baseUrl}${event.show_count_url}`}
                className="img-fluid"
                alt=""
                loading="lazy"
              />
              <div className="overlaycontent">
                <div className="w-100">
                  <h2>{event.name}</h2>
                  <div className="bottombx d-flex justify-content-between">
                    <div className="width80">
                     {event.event_status === "upcoming" && event.dateLabel === "" ? "" : <h3>
                        <i className="fa fa-calendar" aria-hidden="true" />
                        <span
                          dangerouslySetInnerHTML={{ __html: event.dateLabel }}
                        />
                      </h3>}
                     {event.event_status === "upcoming" && event.location === "" ? "" :
                     <p className="mb-0">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        {event.location}
                      </p>}
                      {event.event_status === "upcoming" ? (
                        <button className="btn btnbookover mt-3"  >
                          {" "}
                          Upcoming
                        </button>
                      ) : (
                        <Link
                          className="btn btnbookover mt-3"
                          to={`event/${event.slug}`}
                        >
                          Know More
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="extraarrowbox">
        <div className="l-arrow-circle" onClick={prevSlide}></div>
        <div className="r-arrow-circle" onClick={nextSlide}></div>
      </div>
    </div>

    //   <div className="col-sm-6 col-md-6 mb-4">
    //   <div className="secTitle">
    //     <h2>Adventure</h2>
    //   </div>
    //   <div className="happeningbox">
    //     <div className="slides-container">
    //       {adventureEvents.map((event, index) => (
    //         <div
    //           key={index}
    //           className={`slide ${index === currentSlide ? 'active' : ''}`}
    //           style={{ transform: `translateX(-${currentSlide * 100}%)` }}
    //         >
    //           <img src={`${baseUrl}${event.show_count_url}`} className="img-fluid" alt="" />
    //           <div className="overlaycontent">
    //             <div className="w-100">
    //               <h2>{event.name}</h2>
    //               <div className="bottombx d-flex justify-content-between">
    //                 <div className="width80">
    //                   <h3>
    //                     <i className="fa fa-calendar" aria-hidden="true" />
    //                     <span dangerouslySetInnerHTML={{ __html: event.dateLabel }} />
    //                   </h3>
    //                   <p className="mb-0">
    //                     <i className="fa fa-map-marker" aria-hidden="true" />
    //                     {event.location}
    //                   </p>
    //                   <Link className="btn btnbookover mt-3" to={`event/${event.slug}`}>
    //                     Book Now
    //                     <i className="fa fa-angle-double-right" aria-hidden="true" />
    //                   </Link>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    //   <div className="extraarrowbox">
    //     <div className="l-arrow-circle" onClick={prevSlide}></div>
    //     <div className="r-arrow-circle" onClick={nextSlide}></div>
    //   </div>
    // </div>
  );
};

export default AdventureSlider;
