import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl, getLiveSocialsAPI } from "../APIs/apis";

const HomeSocial = () => {
  const [socialData, setSocialData] = useState([]);
  const getSocialData = async () => {
    try {
      const res = await getLiveSocialsAPI();

      setSocialData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSocialData();
  }, []);

  const sliceDescription = (description) => {
    const maxLength = 300;
    let slicedDescription = description.slice(0, maxLength);
    if (description.length > maxLength) {
      const lastSpaceIndex = slicedDescription.lastIndexOf(" ");
      if (lastSpaceIndex !== -1) {
        slicedDescription = slicedDescription.slice(0, lastSpaceIndex);
      }
      slicedDescription += "...";
    }
    return slicedDescription;
  };
  return (
    <div>
      <section className="blueband mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="h5">SOCIAL</h5>
              <h3 className="h3">Check out what’s trending</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="socialtraening pt-5 pb-5">
        <div className="container">
          <div className="row">
            {socialData &&
              socialData
                .map((item, index) => (
                  <div key={index} className="col-md-4 col-sm-6 mb-4">
                    <div className="boxsocial"  style={{ width: "350px",height:"350px" }}>
                      <img
                        src={baseUrl + item.social_media_content_file}
                        className="img-fluid"
                        alt=""
                        loading="lazy"
                       
                      />
                      <div className="box-content">
                      <h3 className="title">{sliceDescription(item.description)}</h3>
                        <ul className="icon">
                          <li>
                            <Link to={item.social_media_url} target="_blank">
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
                ?.slice(0, 6)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeSocial;
