import "./style/css/bootstrap.min.css";
import "./style/css/mapping/bootstrap.css";
import "./style/css/custom.css";
import "./App.css";

import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Home from "./pages/Home";
import Testimonial from "./pages/Testimonial";
import Adventure from "./pages/Adventure";
import Gallery from "./pages/Gallery";
import Carnival from "./pages/Carnival";

import { Toast } from "./Components/Notify";
import AboutPage from "./pages/AboutPage";
import RegisterPage from "./pages/RegisterPage";
import ContactUs from "./pages/ContactUs";
import TermConditionPage from "./pages/TermConditionPage";
import FaqPage from "./pages/FaqPage_ANUPAM";
import LegalDisclaimerPage from "./pages/LegalDisclaimerPage";
import EventRegistrationPage from "./pages/EventRegistrationPage";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/Cancel";
import EventDetailPage from "./pages/EventDetailPage";
import FansOfHyundai from "./pages/FansOfhyundai";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect } from "react";
 
import CookieConsentComponent from "./CookieConsent";
import RegistrationCarnivalPage from "./pages/RegistrationCarnivalPage";
import ThankyouCarnival from "./pages/Thankyou-carnival";


function App() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <main role="main">
      <Toast />
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/testimonials" element={<Testimonial />} />
          <Route path="/adventure-drive" element={<Adventure />} />
          <Route path="/carnival-drive" element={<Carnival />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about-hyundai-explorers" element={<AboutPage />} />

          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/term-condition" element={<TermConditionPage />} />
          <Route path="/faqs" element={<FaqPage />} />
          <Route path="/legal-disclaimer" element={<LegalDisclaimerPage />} />
          {/* new page privacy policy add */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/thank-you" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/fans_of_hyundai" element={<FansOfHyundai />} />
          {/* slug  */}
          <Route path="/register/:slugId" element={<EventRegistrationPage />} />
          <Route path="/event/:slug" element={<EventDetailPage />} />
          <Route
            path="/adventure/event-detail/:event"
            element={<EventDetailPage />}
          />
          <Route
            path="/carnival/event-detail/:event"
            element={<EventDetailPage />}
          />
          {/* ThankyouCarnival */}
          <Route path="/thankyou-carnival" element={<ThankyouCarnival />} />
{/* carnival registare   carnival-register */}

<Route path="/carnival-register/:slugId" element={<RegistrationCarnivalPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
{/* cookie consent */}
      <CookieConsentComponent  />
    </main>
  );
}

export default App;
