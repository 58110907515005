import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { userRegistrationAPI } from "../APIs/apis";
 
import { useNavigate } from "react-router-dom";
import { Notify } from "../Components/Notify";
 
 
var query = require("india-pincode-search");
const RegisterPage = () => { 
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Register | HyundaiExplorers";
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");

  const [autoFillAddress, setAutoFillAddress] = useState([]);

  const submitHandler = async (event) => {
    event.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      mobile,
      dob,
      gender,
      city,
      pinCode: pincode,
      state,
    };

    if (!event.target.checkValidity()) {
      // The form is not valid, show validation feedback
      event.target.classList.add("was-validated");
    } else {
      // The form is valid, proceed with form submission

      try {
        const response = await userRegistrationAPI(data);

        if (response.status === 201) {
          Notify("success", response.data.message);
          navigate(`/user-verification/${data.email}`);
        } else {
          // Check if the error is due to duplicate email
          if (
            response.response.data &&
            response.response.data.errorCode === "EMAIL_ALREADY_EXIST"
          ) {
            Notify(
              "error",
              "Email already exists. Please use a different email."
            );
          } else {
            // Check if response.data is defined before accessing its properties
            const errorMessage = response.data
              ? response.data.message
              : "An error occurred.";
            Notify("error", errorMessage);
          }
        }
      } catch (error) {
        console.error("Error submitting registration:", error);
        Notify("error", "An error occurred while processing your request.");
      }
    }
  };

  const [message, setMessage] = useState();

  const getPincodeAddress = async () => {
    try {
      // Check if pincode is not null and has a length of 6 or more
      if (pincode.trim().length >= 6) {
        // const res = await axios.get(
        //   `https://api.postalpincode.in/pincode/${pincode}`
        // );

        const res = query.search(pincode);

        setMessage();
        setAutoFillAddress(res);
        setCity(res[0].city);
        setState(res[0].state);
        // if (res.status === 200) {
        //   const postOffice = res?.data[0]?.PostOffice[0];
        //   if (postOffice) {
        //     setAutoFillAddress(postOffice);
        //     setCity(postOffice.District);
        //     setState(postOffice.State);
        //   }
        // }
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  useEffect(() => {
    getPincodeAddress();
  }, [pincode]);

  return (
    <section className="loginformbox pt-5 mt-3 pb-5 my-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="formheading">
              <h2>
                <span>Register Here!</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="formcontrollerbx bg-mode shadow rounded-3 overflow-hidden p-4">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {/* Form START */}
              <Form
                className="form mt-0 text-left"
                noValidate
                onSubmit={submitHandler}
              >
                <div className="form-row">
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label>First Name</label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label>Last Name</label>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="E-mail ID*"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label>E-mail ID*</label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile number*"
                        required
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                      <label>Mobile number*</label>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Birth*"
                        required
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                      <label>Date of Birth*</label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="form-group mb-4">
                      <select
                        className="form-control"
                        required
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Select gender </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      <label>Gender*</label>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="pincode"
                        required
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                      <label>Pincode*</label>
                      {message && pincode && <span> {message} </span>}
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        required
                        value={city}
                        onChange={(e) =>
                          setCity(autoFillAddress?.District || e.target.value)
                        }
                      />
                      <label>City*</label>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-4">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State / Province*"
                        required
                        value={state}
                        onChange={(e) =>
                          setState(autoFillAddress?.state || e.target.value)
                        }
                      />
                      <label>State / Province*</label>
                    </div>
                  </div>
                </div>

                <div className="form-row mb-5">
                  <div className="col-sm-12">
                    <div className="checkboxmain colorchng mr-sm-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="interestedbx"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="interestedbx"
                      >
                        I am interested in future Drives.
                      </label>
                    </div>
                    <div className="checkboxmain colorchng">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="termsbx"
                      />
                      <label className="form-check-label" htmlFor="termsbx">
                        I accept the{" "}
                        <strong>
                          <u>terms and conditions</u>
                        </strong>
                        .
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <Button className="btn btn-primary widthincres" type="submit">
                    Register
                  </Button>
                </div>
                <div className="formheading text-center mt-5">
                  <h2>
                    Having trouble signing in? Write to us{" "}
                    <strong>
                      <a href="#">
                        <u>info@hyundaiexplorers.com</u>
                      </a>
                    </strong>
                  </h2>
                </div>
                {/* Copyright */}
              </Form>
              {/* Form END */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterPage;
