import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
const TermConditionPage = () => {
  const [activeTab, setActiveTab] = useState("tab-1");
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  useEffect(() => {
    document.title = "Terms&Condition | HyundaiExplorers";
  }, []); 
  return (
    <section className="tandcbx pt-5 pb-5 my-3">
      <div className="container">
        <div className="tabpillsbox mt-3">
          <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
            <Nav
              variant="pills"
              className="nav nav-pills justify-content-center mb-5"
              id="pills-tab"
              role="tablist"
            >
              <Nav.Item className="nav-item">
                <Nav.Link
                  eventKey="tab-1"
                  className={`nav-link ${activeTab === "adventure" ? "active" : ""}`}
                  id="adventure-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="tab-1"
                  aria-selected={activeTab === "adventure"}
                >
                  Adventure
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link
                  eventKey="tab-2"
                  className={`nav-link ${activeTab === "carnival" ? "active" : ""}`}
                  id="carnival-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="tab-2"
                  aria-selected={activeTab === "carnival"}
                >
                  Carnival
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
            <div class="secTitle">
                  <h2>Terms and Conditions</h2>
                </div>
              <Tab.Pane eventKey="tab-1">
                 
                  <div className="tctabrow">
                     
                    <div className="olcontentbx">
                      <h3>LEH Adventure Terms & Conditions</h3>
                      <ol>
                        <li>
                          <strong>Organisers:</strong> Innocean Worldwide Communication Private Limited (“IIN”), authorized by Hyundai Motor India Limited (“Hyundai”) to undertake and organize Hyundai Explorer Drives (the “Drives”) and related activities, has engaged, on a principal-to-principal basis, Cougar Motorsport Private Limited (“Cougar”), an entity engaged in the business of organising driving expeditions. Herein, Cougar its employees, affiliates, sub-contractors, vendors, etc. are referred to as the “Organisers”.
                        </li>
                        <li>
                          <strong>Drive:</strong> The term Drive for the purpose of these terms and conditions particularly refers to the planned journey or expedition organized by Hyundai for its SUV customers to Leh ("Drive"). This journey for 10 days includes travelling from the starting point to Leh and back, covering various terrains and locations along the route. The Drive encompasses a structured itinerary, accommodations, activities, and any associated risks or responsibilities outlined in the T&C. Under no circumstances shall Hyundai entertain any request for diversion from the structured itinerary which has been prepared keeping the safety of the Participants and any diversion undertaken by any Participant for any reason whatsoever shall be at the sole costs and risks of such participant. The Participants are expected to comply with all rules, guidelines, and safety measures set forth by the Organisers for the duration of the Drive.
                        </li>
                        <li>
                          <strong>Participant & Age Group Restrictions:</strong> By registering for the Leh-Drive and making a payment of the Booking amount as described hereunder, Participant / s (“You”) agree to be bound by these Terms and Conditions. The Leh-Drive is open exclusively to Indian nationals, above 18 years of age, residing in India, who own Hyundai SUV (“Vehicle”) and only such owners of the Vehicle who fulfil this criteria as well as any criteria as many be mentioned hereunder are eligible to participate in the Drive. Each vehicle is allowed a maximum number of 3 people only. The chauffeurs/drivers appointed by the owners are ineligible to participate in the Drive. The Participants must be between the ages of 18 and 60 years. As Co-passenger, children below 12 years and adults above 60 years are expressly prohibited from participating in this Drive.
                        </li>
                        <li><strong>Medical Conditions:</strong> Participants with major medical conditions such as asthma, heart-related issues, high blood pressure, or any other condition that may be exacerbated by high altitudes are strongly advised against participating in this Drive. It is hereby clarified that the Drive is suitable only for fully fit, healthy- both physically and mentally, resilient adults. Should such Participants choose to join the drive, they do so at their own risks and costs. The Organisers shall bear no responsibility for any health-related incidents and/or death arising from the Participant's decision to partake in the Drive. For additional terms and conditions via-a-vis medical conditions applicable herein, please refer to the T&C of official Hyundai Explorers Adventure 2024. </li>
                        <li><strong>Hotel:</strong> Due to the limited availability of accommodation options along the route to Leh and back, participants will be divided among multiple hotels. The allocation of rooms will be conducted strictly on a first-come, first-served basis. Special requests for specific hotels or room changes will not be entertained under any circumstances and any diversion from the allocated bookings, if undertaken by the Participants, shall be at the sole risks and costs of such Participant. Room allocation will be based solely on the order of registration. For additional terms and conditions via-a-vis hotel applicable herein, please refer to the T&C of official Hyundai Explorers Adventure 2024.</li>
                        <li><strong>Delays or Changes Due to Weather:</strong>
                        <ol type="a">
                          <li>Weather conditions in Leh can change rapidly and are difficult to predict. Consequently, delays caused by weather are unpredictable and unavoidable. Participants may need to wait on the road for weather and road conditions to improve, or alternative routes may need to be taken, potentially extending the duration of the drive. Participants must acknowledge and accept these conditions to participate in the drive.</li>
                          <li>The Organisers and Hyundai shall not be responsible for any delay or alterations in the itinerary or expenses incurred - directly or indirectly by Participants due to weather conditions, including but not limited to, natural hazards, landslides, Road Closure and/or  any untoward incident whether relating to weather condition or otherwise.</li>
                        </ol>
                        The Organisers are not responsible for any mishap or unforeseen circumstances that may occur at any point of time. The Participants are also recommended to carry necessary equipment and clothing attire to deal with the difficult weather terrain prevalent at Leh and to keep a close check on Leh weather conditions beforehand.
                        </li>
                        <li><strong>Itinerary Changes:</strong>
                        <ol type="a">
                          <li>The sequence of the day-wise itinerary tour program may be altered based on traffic schedules, restrictions, weather and road conditions, or other unforeseen events.</li>
                          <li>The itinerary is subject to changes due to traffic schedules, restrictions, landslides, route alterations, weather conditions, or any other unforeseen exploration-related circumstances.</li>
                          <li>The Organisers reserve the right to make changes to the itinerary without prior permission or notice to the participants. Changes will be communicated at the earliest possible opportunity. In the event of changes post commencement of the journey, the Organisers will inform the participants as soon as feasible, prioritizing the safety and comfort of all participants. The Organisers shall not be held liable for any loss resulting from such changes.</li>
                        </ol>
                        </li>
                        <li><strong>Booking Amount:</strong> For participating in the Drive, the Participants have to pay an amount of INR59,999/- for 2 participants & INR.29,999/- for 3rd participant (“Booking Amount”) exclusive of GST and other taxes.  The above-stipulated Booking Amount for the Drive is contingent upon various elements of the Drive and will vary accordingly.  The Participants are encouraged to carefully go through the T&C of the official Hyundai Explorers Adventure 2024 to gain deeper insight into the inclusions and exclusions of the Drive.
                        <p>The Participants are also hereby informed that mere payment of the Booking Amount does not guarantee any interested Participant the right to partake in the Drive and the same will be subject to the evaluation of documentation, availability and such other criteria as may be determined in the sole discretion of the Organizers as also the Selection criteria envisaged in the T&C of official Hyundai Explorers Adventure 2024.</p></li>
                        <li><strong>Safety and Compliance:</strong>
                          <ol type="a">
                            <li>The Organisers will implement all reasonable safety measures to manage the Participants. Participants must comply with all instructions for their own safety and the safety of others.</li>
                            <li>Any violation of instructions may result in the immediate cancellation of the Participant's involvement in the Drive, with no refund of fees paid.</li>
                          </ol>
                        </li>
                        <li><strong>Rights of the Organiser:</strong>
                        <ol type="a">
                          <li>The Organisers shall not be responsible for any delays or alterations in the itinerary or any expenses incurred due to natural hazards, accidents, mechanical breakdowns, weather, sickness, landslides, political closures, prohibition orders, local unrest, traffic jams, medical emergencies, or any other untoward incidents.</li>
                          <li>The Organisers reserve the right to amend, alter, vary, or withdraw any departure or excursion advertised, reverse the order of places to be visited, or substitute a hotel of similar class if deemed necessary without prior notice.</li>
                          <li>The Organisers shall not be liable for any loss incurred by Participants who miss any activity due to their own delay. No refunds will be provided under such circumstances.</li>
                          <li>Participants must strictly adhere to the prescribed schedule for each day. No refunds will be provided for missed schedules due to delays caused by Participants or any other reasons.</li>
                          <li>The Organisers reserve the right to withdraw the participation of any Participant whose behaviour is deemed likely to affect the smooth operation of the Drive or adversely affect the enjoyment or safety of other co-passengers and the Organisers shall be under no liability to any such expelled Participants.</li>
                          <li>The positioning and numbering of the vehicles in convoy during the Drive will be at sole discretion of organisers and no representation or communication in this regard will be entertained by the organisers.</li>
                          <li>The Organisers reserve the right to claim any additional expenses because of delays or alterations in the itinerary, caused by any reason whatsoever.</li>
                          <li>Participant's itinerary and accommodation are subject to change, based on availability. The Organisers reserve the right to replace the itinerary and accommodation with similar alternatives.</li>
                          <li>The positioning and numbering of the vehicles in convoy during the Drive will be at sole discretion of organisers and no representation or communication in this regard will be entertained by the organisers.</li>
                          </ol></li>
                          <li><strong>Participants and Co-passengers:</strong>
                          <ol type="a">
                            <li>Participants are allowed to bring their family members, including children, or any other individual as co-passengers for the Drives. However, each Vehicle is only allowed a maximum of 3 people. </li>
                            <li>Participants cannot transfer the opportunity of a Drive to any third party or modify/ postpone it to a later date.</li>
                            <li>No Participant shall have any criminal proceeding pending against them and the Vehicle shall not be involved in any criminal incident or dispute. Any such Participants are not eligible to register/ attend the Drive.</li>
                            <li>The Participant shall follow convoy rules and all the instructions of the designated group leader appointed during the Drive. Any actions of the Participant in deviation to the convoy rules and to group leader’s instructions may expose them and others to potential dangers and the disobedient Participant shall be held responsible for any loss, injury, damage caused to the other co-passengers. Further, the Participant shall also be liable to get disqualified without any refund in event of any deviation from group leader's instructions or convoy rules.</li>
                            <li>The Participants shall follow a strict no alcohol consumption policy while on the Drive and consumption and carrying of alcohol is strictly prohibited during the Drive. The Organisers will have the right to immediately disqualify the Participant including attendees who are found to be breaking this regulation, and they will not be entitled to a refund. In the sake of the other Participants' safety, the organisers may take any necessary action that they feel appropriate.</li>
                            <li>The Participants shall follow the package itinerary. There shall be no refund, if the Participant fails to join the group at the appointed time of commencement of the Drive or join the group later or leave the group before the culmination of the Drive. Please note that for all purposes, it shall be the responsibility of the Participants to reach the place of commencement of the drive and register with the Organisers at the appointed place on the given date and time.</li>
                            <li>In the event a Participant misses out on any activity on the Drive due to delay on his/her part, the Organisers shall not be held liable to refund the money paid for the same or in any other manner whatsoever.</li>
                            <li>Participants will have to strictly adhere to the prescribed timetable for the day so the Drive can be completed within the defined schedule. In case any of the sightseeing schedules are missed due to delays caused by a Participant or for any other reasons, the same shall not be refunded to the Participant under any circumstances.</li>
                            <li>In case a Participant is compelled to discontinue the Drive due to any reason whatsoever including illness, death or loss of any travel documents, no claim shall be entertained for refund of unutilized services. Further, the Participants shall pay for any towing / transportation costs involved in moving the Vehicle.</li>
                            </ol></li>
                            <li><strong>Health and Insurance:</strong>
                            <ol type="a">
                              <li>This Event is an adventure event that places considerable demands on both Participants and their equipment. Driving on difficult terrain may cause extreme stress, and conditions such as sand, dust, high humidity, and unfavorable weather may impact participants and their personal equipment. This journey is suitable only for fully fit, healthy, and resilient adults.</li>
                              <li>Each Participant should only undertake activities they feel capable of performing. Many activities involve risk, and each participant must decide for themselves what risks they are willing to assume for they will be wholly responsible for the consequences of such risks and the Organizers and Hyundai will bear no responsibility of such consequences.</li>
                              <li>Participants must inform the Organisers of any medical conditions (e.g., diabetes, hypertension, heart disease), medical history (including COVID-19), or prescribed restrictions/allergies that may affect their ability to participate fully in the journey.</li>
                              <li>If a medical condition is disclosed, the Organisers will endeavor to provide basic medical assistance within their means. Participants must comply with decisions deemed most appropriate by the Organisers. Refusal to follow such decisions releases the Organisers from all responsibility. No claims for refunds of incurred expenses will be entertained.</li>
                              </ol></li>
                              <li><strong>Force Majeure:</strong> Neither party shall be held liable for damages or deficiency in service or loss, injury, expense, etc., in the event of the Drive being delayed or cancelled or aborted after commencing due to any force majeure events including but not limited to Acts of God, natural calamities, prohibition orders, Government restrictions, lockdown, epidemic, pandemic, local unrest, traffic jams, medical emergency, accidents, etc ("Force Majeure Event").
                              <p>The Organisers shall be under no obligation to resume the Drive on the situation having normalized after the Force Majeure Event.</p></li>
                            <li><strong>Liability:</strong>
                            <ol type="a">
                              <li>The Organisers shall not be responsible and/or liable for any damages caused to the Participant due to circumstances beyond the control of the Organisers (Force Majeure / Vis Majeure / Acts of God).</li>
                              <li>The Organisers shall not be responsible for the temporary or permanent loss of or damage to baggage or personal effects howsoever caused including wilful negligence on the part of any person.</li>
                              <li>The Organisers shall not be responsible for any death, personal injury, sickness, accident, loss, delay, discomfort, increased expenses, any direct loss or damage including financial loss, or loss of contracts or goodwill or for any indirect, special or consequential loss and/or damage or any kind of theft howsoever caused and the Participant hereby forever discharges them of and from all actions, causes of action, suits, debts, obligations, claims and demands whatsoever which the Participant has or hereafter can, shall, or may have.</li>
                              </ol></li>
                          <li><strong>Indemnity</strong>
                          <ol type="a">
                            <li>The Participant agrees to indemnify, defend, keep indemnified and hold harmless the Organisers, IIN and Hyundai, their directors, employees, officers, authorized representatives, agents and/or their service providers against any claims, proceedings, damages, demands, judgments (including advocate fees), liability, loss or expenses if any arising:
                              <ol type="i">
                                <li>out of or in connection with the participation of the Participant in the Event;</li>
                                <li>from the operation, condition or use of the Vehicle by the Participant in the Event, including those arising from a direct or vicarious liability/ claims including from a third party resulting out of his/her act or omission during the entire Event;</li>
                                <li>out of any damage, death or injury to persons including that of the Participant itself, or any other third party or any other occupant/co-passenger/s of the Vehicle;</li>
                                <li>out of any loss or damage to any property of the third party including the Vehicle or otherwise as a result of any rash or negligent driving, dis-obeying the traffic rules, over speeding or any other reason of whatsoever nature by the Participant during the Event;</li>
                                <li>out of or due to breach by the Participant of the terms and conditions of or the breach of any of the undertakings given by him/her hereunder</li>
                              </ol>
                            </li>
                            <li>While participating in the trials, test runs, endurance tests, performance appraisals, or any event as part of the Event, either by himself/herself or with any person to whom he/she has delegated on their behalf or while being driven by any other person while the vehicle is in their possession, the Participant shall not make any claim against the Organisers, its directors, employees, officers, authorized representatives, agents and/or its Service Providers for any loss, damage or injury caused to the Participant or to any other person in any manner whatsoever.</li>
                            <li>The indemnity above shall apply Regardless of Cause. For the purposes hereof, “Regardless of Cause” means without regard to negligence, in whole or in part, of the Party seeking indemnity or of any other person.</li>
                            </ol></li>  
                          <li><strong>Governing Law and Jurisdiction:</strong> For any claims, suits, complaints, or disputes relating to the Drive and service provided by the Organisers therein, the competent courts in New Delhi, India alone shall have exclusive jurisdiction.</li>
                          <li><strong>Miscellaneous</strong>
                          <ol type="a">
                            <li>The Participants are hereby informed that the present terms and conditions are over and above the T&C posted on the official Hyundai Explorers Adventure 2024.</li>
                            <li>Any terms and conditions relating to the Drive not specifically covered herein will be governed by the T&C posted on the official Hyundai Explorers Adventure 2024.</li>
                            <li>Participants are hereby informed that to the extent limited to the Drive as defined hereinabove, in case of any inconsistency between the present terms and conditions and the terms and conditions of Hyundai Explorers Adventure 2024, these terms and conditions shall prevail.</li>
                            </ol></li>
                      </ol>
                      <h3>Adventure Drive Generic Terms & Conditions</h3>
<ol>
    <li>
        <p><strong>Organisers</strong>: Innocean Worldwide Communication Private Limited (&ldquo;IIN&rdquo;), authorized by Hyundai Motor India Limited (&ldquo;Hyundai&rdquo;) to undertake and organize Hyundai Explorer Drives (the &ldquo;Drives&rdquo;) and related activities, has engaged, on a principal-to-principal basis, Cougar Motorsport Private Limited (&ldquo;Cougar&rdquo;), an entity engaged in the business of organising driving expeditions. Herein, Cougar its employees, affiliates, sub-contractors, vendors, etc. are referred to as the &ldquo;Organisers&rdquo;.</p>
    </li>
</ol>
<ol start="2">
    <li>
        <p><strong>Participants &amp; eligibility</strong>: By registering for the Drives and making a payment of the agreed amount to the Organisers (&ldquo;Entry Fee&rdquo;), Participant / s (&ldquo;You&rdquo;) agree to be bound by these Terms and Conditions. The Drives are open exclusively to Indian nationals, above 18 years of age, residing in India, who own Hyundai SUV (&ldquo;Vehicle&rdquo;). Only such owners of the vehicle, along with their friends and family members are eligible to participate in the Drives. The chauffeurs/drivers appointed by the owners are ineligible to participate in the Drives. The Participant is allowed to enrol for a maximum of two (2) similar duration Drives in any given year and the Entry Fee for each drive will be independent and separate.</p>
    </li>
</ol>
<p>Please note that the Terms and Conditions, including the Event Fee and the eligibility criteria may differ certain Drives due to specific climate issues, drive/road/route conditions. We recommend that you review the specific Terms and Conditions for each Drive when booking, especially if the destination may pose safety concerns, to ensure the well-being of you and your family.</p>
<ol start="3">
    <li>
        <p><strong>Background</strong>: The Organisers are organizing an event (hereinafter called as the &ldquo;<strong>Event</strong>&rdquo; or the &ldquo;<strong>Drive</strong>&rdquo;). The Participant had expressed willingness to participate in the Event as a participant/ occupant/co-passenger of a vehicle and the Organisers have agreed to such request, subject to the present terms and conditions.</p>
    </li>
</ol>
<ol start="4">
    <li>
        <p><strong>Third Parties:</strong></p>
        <ol type="a">
            <li>
                <p>Although Cougar is making the arrangements for the Drives, its responsibility is limited. Cougar is involved in the bookings related to the Drives but does not act as an agent for any party whatsoever. The limits of its responsibility arise from the fact that it liaises with independent third parties such as independent tour operators, hotel companies, transportation companies and/ or drivers and other ground operators and suppliers to supply goods and services to Participants; however, such third parties are not Cougar&rsquo;s employees or agents or employers or principals. Accordingly, each Participant agrees that Cougar shall not and shall be deemed not to manage, control or supervise the goods or services they supply and shall not be liable for any acts or omissions, including any negligence, gross negligence, or reckless or wilful acts, on the part of such third parties or any other defect in such goods or services. By accepting and utilising the goods and/ or services of said third parties, each Participant agrees that Cougar shall not be held liable in any way for any injury, loss, damage, delay, or death or property loss or damage arising from any act or omission of any such third party.</p>
            </li>
            <li>
                <p>Each Participant further understands and agrees that Hyundai is no manner involved in undertaking the Drives and consequently, shall not and shall be deemed not to manage, control or supervise either the Organisers or the third party suppliers for the Drives, who are all a part of the Drives on a principal-to-principal basis and shall not be liable for any acts or omissions, including but not limited to any negligence, gross negligence, or reckless or wilful acts, on the part of the Organisers or such third parties or any other defect in the Drives or such goods or services. By participating in the Drives and accepting and utilising the goods and/ or services of said third parties including Cougar, each Participant agrees that Hyundai shall not be held liable in any manner or for whatsoever reason including but not limited to any injury, loss, damage, delay, or death or property loss or damage arising from any act or omission of the Organisers or any third party.</p>
            </li>
        </ol>
    </li>
</ol>
<ol start="5">
    <li>
        <p><strong>Registration</strong>: In order to register for the Drives, the interested Participants has:</p>
        <ol type="a">
            <li>
                <p>Submitted their entries (&ldquo;Entry&rdquo;) on or before the prescribed last date mentioned for respective Events by logging-into the microsite, www.hyundaiexplorers.com/in, interfaced/linked with the website, www.hyundai.com/in (collectively, the &ldquo;Website&rdquo;). Please ensure that you fill in all the required information, including a valid phone/mobile number and email ID, and upload all mandatory documents. The Organisers may at their discretion, extend or reduce the timeline for submission of the Entries.</p>
            </li>
            <li>
                <p>Paid the Entry fee per couple or for two adults and any additional fee for each additional co-passenger (the &ldquo;Entry Fee&rdquo;).</p>
            </li>
            <li>
                <p>Submitted the required documentation.</p>
            </li>
            <li>
                <p>It is hereby clarified that by merely registering for the Event does not and will not guarantee any interested participant to be conclusively a part of the Event and the same will be subject to the evaluation of documentation, availability and such other criteria as may be determined in the sole discretion of the Organizers.</p>
            </li>
        </ol>
    </li>
</ol>
<ol start="6">
    <li>
        <p>Entry Fee for Drive Package: Participants are hereby informed that the Entry Fee for the Drive has the following inclusions and exclusions:</p>
    </li>
</ol>
<p><strong>Inclusions:</strong></p>
<ol type="a">
    <li>
        <p>Accommodation on twin / double occupancy</p>
    </li>
    <li>
        <p>Meals viz., breakfast, lunch and dinner (both vegetarian and non-vegetarian) as per the restaurant offerings), refreshments at pitstop/bio break and in-vehicle refreshments</p>
    </li>
    <li>
        <p>Permits and entry and other charges for activities and experiences</p>
    </li>
    <li>
        <p>Local guidance from Drive experts and returnable wireless radio set in individual Vehicles</p>
    </li>
    <li>
        <p>Gifts and giveaways</p>
    </li>
</ol>
<p><strong>Exclusions:</strong></p>
<ol type="a">
    <li>
        <p>Flight tickets to and from respective Drive locations and airport transfers</p>
    </li>
    <li>
        <p>Any costs to be incurred towards transporting the vehicle of the participant from their respective destination to the commencement point of the drive.</p>
    </li>
    <li>
        <p>Fuel and toll charges and Vehicle servicing, repairs and traffic fines</p>
    </li>
    <li>
        <p>Insurances, pollution under control certificates, etc.</p>
    </li>
    <li>
        <p>Off-road shoes/gumboots</p>
    </li>
    <li>
        <p>Any additional service such as refreshments/meals for visitors provided by the hotel and any refreshment from the mini fridge, room dine-in or alcoholic beverages</p>
    </li>
    <li>
        <p>Pre and post Drive accommodation expenses and/or Additional accommodation charges due to unforeseen delays in the journey</p>
    </li>
    <li>
        <p>Any charges for carrying video camera or still camera Any hotel, subsistence or other cost incurred prior to official start date of the Drive and after official end date of the Drive</p>
    </li>
    <li>
        <p>Any kind of personal expenses or optional tours, extra meals, in-room dining, and beverages ordered, laundry, phone calls and medical expenses</p>
    </li>
</ol>
<ol start="7">
    <li>
        <p><strong>Cancellation &amp; Refund of Entry Fee</strong>: In the event that a Participant cancels his/her participation in a Drive:</p>
        <ol type="a">
            <li>
                <p>30 or more days prior to the Drive start date, 75% of the Entry Fee will be refunded.</p>
            </li>
            <li>
                <p>15 to 29 days prior to the Drive start date, 50% of the Entry Fee will be refunded.</p>
            </li>
            <li>
                <p>14 days or less to the Drive start date, no refunds will be entertained and the entire cost of the Drive will be forfeited.</p>
            </li>
            <li>
                <p>All refunds will take up to 14 working days to process. The refund amount will be remitted to the same bank account/card from which Entry Fee was received.</p>
            </li>
            <li>
                <p>The Entry Fee paid will be fully refunded in case the registration is rejected by the Organisers or in case a Drive is cancelled by the Organisers (except for reasons outside their control). The Entry Fee will not be refunded for any other reason (including Force Majeure Events) except those agreed above.</p>
            </li>
            <li>
                <p>The Entry Fee charged by the Organisers is non-transferable and non-interchangeable with any goods and services by Cougar, Hyundai and/or any third party involved in the drive.</p>
            </li>
        </ol>
    </li>
    <li>
        <p><strong>Selection</strong>: All the valid Entries submitted by the Participants will be evaluated by the Organisers. The decision of the Organisers in selecting the Participants will be final and binding on all the potential participants and no correspondence in this regard, shall be entertained under any circumstances of whatsoever nature. The Organisers reserve the right to restrict number of Entries/Participants, as they may deem fit at their sole discretion without any prior notice or justification.</p>
    </li>
</ol>
<p>Any employee or associate of Hyundai, including but not restricted to vendors &ndash; print, audio and visual media, production houses, Hyundai dealers &ndash; is not eligible to participate in the Drives. The Organisers reserve the right at their sole discretion to cancel/withdraw participation of any Participant who is found to be an employee or associate of Hyundai, in the manner as described above and its decision in this regard would be final and binding.</p>
<p>The Organisers reserve the right at their sole discretion to disqualify any Participant that they determine to be tampering with the process or operation of the Drives, and to be acting in breach or potential breach of these Terms and Conditions. Eligible Participants understand and agree that they are not compelled in any way by the Organisers to participate in the Drives and any such participation is entirely voluntary. The submission of false, incorrect, misleading, or fraudulent documentation at the time of registration may result in disqualification from the Drive and entire amount / deposit / fee whatever received will be forfeited.</p>
<p>Notwithstanding anything contained in the clauses of the present terms and conditions, the selection or rejection of any of the Participant shall be at the sole discretion of the organisers at any time without assigning any reasons and/or providing notice for the same.</p>
<ol start="9">
    <li>
        <p><strong>Participation</strong>: The Participant must reach the Drive starting point in his/ her own personal Vehicle along with all original requisite documents on the prescribed date and time for pre-drive verification. In the event that a Participant is unable to reach the Drive starting point at the scheduled date and time of the Drive due to any reason whatsoever, his/her booking shall be treated as a "no show" on the Drive and no refund will be paid. Further, the Participant acknowledges and agrees that if he/she arrives at the Drive starting point not in his/her personal Vehicle but in a borrowed, rented, leased, etc. vehicle then in such event the Participant&rsquo;s participation will be rejected/disqualified and without prejudice to clause 7, no refund will be payable to the Participant.</p>
    </li>
</ol>
<ol start="10">
    <li>
        <p><strong>Vehicles</strong>:</p>
        <ol type="a">
            <li>
                <p>The Participant will use his/her own Vehicle in each Drive.</p>
            </li>
            <li>
                <p>The Vehicle shall only be driven by individuals with a full and valid driving license for light motor vehicles. Such driving license shall be carried in original during the Drive and be shown before the Drive begins.</p>
            </li>
            <li>
                <p>The Participant shall ensure that the Vehicle is driven at all times in a safe and responsible manner in compliance with all applicable road traffic laws and regulations including Motor Vehicle Act 1988, Central Motor Vehicle Rules 1989 or any special regulations for the terrain driven on, including ensuring that no-one drives the Vehicle under the influence of alcohol or drugs.</p>
            </li>
            <li>
                <p>The Participant shall fasten her/his seat belt and cause her/his fellow passengers to fasten their seat belts at all times in a moving vehicle.</p>
            </li>
            <li>
                <p>The Participant shall be responsible for the payment of any and all fines, charges or penalties arising from the use of the Vehicle as a result of criminal offences / regulatory offences, or the like.</p>
            </li>
            <li>
                <p>The safekeeping or the Participants&rsquo; Vehicles will remain the sole responsibility of the Participants. Any damage caused to the Vehicles during the Drive because of accidents, negligence, natural causes shall be solely borne by the Participants and the Organisers bear no responsibility or liability for any damages and/or expenses suffered by the Participants on this account.</p>
            </li>
            <li>
                <p>In the event of any breakdown of a Vehicle during the Drive, the Organisers bear no responsibility for arranging an alternative vehicle but will provide assistance for the transportation of the Participants to the scheduled night halt destination of that day as per the itinerary.</p>
            </li>
            <li>
                <p>Fuel and toll charges shall be borne by the Participants for their Vehicles.</p>
            </li>
            <li>
                <p>Organizers shall not be responsible for any claim, dispute, litigation, action etc. whatsoever in nature by government authorities, third party or any other person against the Participant during the drive.</p>
            </li>
        </ol>
    </li>
</ol>
<ol start="11">
    <li>
        <p><strong>Documentation and Insurance</strong>:</p>
    </li>
</ol>
<p>At the time of registration for a Drive, the Participant will be required to produce comprehensive Vehicle insurance, original valid driving licence and registration certificate, pollution certificate and such other valid legal documents as may be required for the Drive.</p>
<p>For abundant caution, it is clarified that the Entry Fee for each Drive does not include costs towards any insurance premium. The Organisers will not obtain any insurance policy covering any risk. The Participants are advised to seek their own appropriate personal accident/travel/medical including COVID-19 insurance coverage as they deem appropriate and at their own cost. All baggage and personal property/s at all times will be at the Participants&rsquo; risk and Participants agree that Organisers will be not be in a position to guard or protect their belongings at any time.</p>
<ol start="12">
    <li>
        <p><strong>Participant and Co-Passenger:</strong></p>
        <ol type="a">
            <li>
                <p>Participants are allowed to bring their family members, including children, or any other individual as co-passengers for the Drives. However, each Vehicle is only allowed a maximum of 3 people.However, please be aware that eligibility of participation and maximum number of people permitted for certain Drives may vary due to challenging climate conditions, road conditions, or routes. Therefore, we strongly recommend checking the specific T&amp;Cs while booking the Drive to ensure the safety and enjoyment of all participants.</p>
            </li>
            <li>
                <p>Participants cannot transfer the opportunity of a Drive to any third party or modify/ postpone it to a later date.</p>
            </li>
            <li>
                <p>No Participant shall have any criminal proceeding pending against them and the Vehicle shall not be involved in any criminal incident or dispute. Any such Participants are not eligible to register/ attend the Drives.</p>
            </li>
            <li>
                <p>The Participant shall follow convoy rules and all the instructions of the designated group leader appointed during the Drive. Any actions of the Participant in deviation to the convoy rules and to group leader&rsquo;s instructions may expose them and others to potential dangers and the disobedient Participant shall be held responsible for any loss, injury, damage caused to the other co-passengers. Further, the Participant shall also be liable to get disqualified without any refund in event of any deviation from group leader&rsquo;s instructions or convoy rules.</p>
            </li>
            <li>
                <p>The Participants shall follow a strict no alcohol consumption policy while on the Drive and consumption and carrying of alcohol is strictly prohibited during the Drive. The Organisers will have the right to immediately disqualify the Participant including attendees who are found to be breaking this regulation, and they will not be entitled to a refund. In the sake of the other Participants' safety, the organisers may take any necessary action that they feel appropriate.</p>
            </li>
            <li>
                <p>The Participants shall follow the package itinerary. There shall be no refund, if the Participant fails to join the group at the appointed time of commencement of the Drive or join the group later or leave the group before the culmination of the drive. Please note that for all purposes, it shall be the responsibility of the Participants to reach the place of commencement of the drive and register with the Organisers at the appointed place on the given date and time.</p>
            </li>
            <li>
                <p>The Organisers shall not be responsible for any delay or alterations in the itinerary or expenses incurred - directly or indirectly by Participants due to natural hazards, accidents, mechanical breakdowns, weather, sickness, landslides, political closures, prohibition orders, local unrest, traffic jams, medical emergency, or any untoward incident. The Organisers are not responsible for any mishap or unforeseen circumstances that may occur at any point of time.</p>
            </li>
            <li>
                <p>The Organisers reserve the right to claim any additional expenses because of delays or alterations in the itinerary, caused by any reason whatsoever.</p>
            </li>
            <li>
                <p>The Organisers reserve the right to, without prior notice, extend the period for submission of Entries, amend, alter, vary, or withdraw any departure, excursion advertised/notified, reverse the order of places to be visited or substitute a hotel of similar class if deemed advisable or necessary during the course of the Drive.</p>
            </li>
            <li>
                <p>The Participant&rsquo;s itinerary and accommodation are subject to change, based on availability. The Organisers reserve the right to replace the itinerary and accommodation with similar alternatives.</p>
            </li>
            <li>
                <p>In the event a Participant misses out on any activity on the Drive due to delay on his/her part, the Organisers shall not be held liable to refund the money paid for the same or in any other manner whatsoever.</p>
            </li>
            <li>
                <p>Participants will have to strictly adhere to the prescribed timetable for the day so the Drive can be completed within the defined schedule. In case any of the sightseeing schedules are missed due to delays caused by a Participant or for any other reasons, the same shall not be refunded to the Participant under any circumstances.</p>
            </li>
            <li>
                <p>In case a Participant is compelled to discontinue the Drive due to any reason whatsoever including illness, death or loss of any travel documents, no claim shall be entertained for refund of unutilized services. Further, the Participants shall pay for any towing / transportation costs involved in moving the Vehicle.</p>
            </li>
            <li>
                <p>The Organisers reserve the right to withdraw the participation of any Participant whose behaviour is deemed likely to affect the smooth operation of the Drive or adversely affect the enjoyment or safety of other co-passengers and the Organisers shall be under no liability to any such expelled Participants.</p>
            </li>
            <li>
                <p>The positioning and numbering of the vehicles in convoy during the Drive will be at sole discretion of organisers and no representation or communication in this regard will be entertained by the organisers.</p>
            </li>
        </ol>
    </li>
</ol>
<ol start="13">
    <li>
        <p><strong>Medical Condition:</strong></p>
        <ol type="a">
            <li>
                <p>The Drive that you have booked is an adventure event that can place considerable demands on man and material. Driving on difficult terrain / in difficult track conditions may lead to extreme stress. Sand, dust, high humidity and unfavourable weather conditions etc. may impact upon you and your personal equipment. Fundamentally, the Drives are suitable only for fully fit, healthy both physically and mentally, resilient adults.Kindly note that specific Drives may have differing terms and conditions based on climate issues and road conditions. Participants are advised to carefully review the terms and conditions related to the specific Drive before booking to ensure a safe and enjoyable experience for themselves and their family.</p>
            </li>
            <li>
                <p>Each Participant should only do what he feels capable of doing. Many activities involve risks. Each Participant must decide himself what risk he wishes to take. If you are travelling as a legal guardian of a Participant, you need to be aware that you will be specifically responsible for assessing the risk and for making decisions on behalf of such Participant.</p>
            </li>
            <li>
                <p>It shall be the duty of the Participants to inform the Organisers in case the Participant has any medical history or prescribed restrictions/allergies that may affect his/her ability to enjoy and pursue fully the drive and wherein the interest of the group or any member thereof is prejudicially affected.</p>
            </li>
            <li>
                <p>If a medical condition has been disclosed by a Participant, the Organisers shall make an effort to provide basic medical help to such Participant, but under no circumstances shall the Organisers be held liable to any injury, disability, death, sickness etc., that the Participant may suffer or be bound to provide any emergency assistance or refund the money.</p>
            </li>
        </ol>
    </li>
</ol>
<ol start="14">
    <li>
        <p><strong>Hotel:</strong></p>
    </li>
</ol>
<p>The Organisers will make best efforts to procure the best hotels available in the price range, prioritizing the comfort and convenience of the Participants as per the itinerary. The selection of hotels will be based on a strong internal assessment. However, it is important to note that the allotment of rooms is not performed by the Organizers. Rooms are allocated through the hotel on a first-come, first-served basis, based on the registration and submission of required documents by the customers. It should be understood that all rooms booked are of the same category and type, hence the allotment of specific rooms is not predetermined. In certain drives, due to the location, Organizers may have to book multiple hotels based on any restrictions pertaining to room availability . In such cases as well, the allotment of rooms will be based on the first-come, first-served basis of registration and documentation submission. While the Organisers will make best efforts to ensure the delivery of services provided by the hotels, they solely act as facilitators between the Participants and the hotels. Therefore, the Organisers shall not entertain any requests for changes in hotels or assume responsibility for any inadequate, deficient, or negligent services provided by the hotels and/or be made responsible for the same. Any complaints or grievances regarding the services provided by the hotels must be directly addressed with the concerned hotel management. Any upgradation chosen by the Participant in the category of rooms shall be at the sole expense of the Participant. The Participants shall be liable to make good any damage(s) caused by any act of such Participant (wilful/negligent) to the property of the hotels in any manner provided by the hotel policy. The extent and the amount of the damage so caused will be as determined by the concerned hotel. The Organisers have no bearing on the same.</p>
<ol start="15">
    <li>
        <p><strong>Children:</strong></p>
        <ol type="a">
            <li>
                <p>Children 5 years and above are eligible to participate in the Drives in the company of their parents/guardians.</p>
            </li>
            <li>
                <p>The parent/guardian signing below agrees to be responsible for his/her children and his/her actions and will ensure he/she complies with his/her obligations.</p>
            </li>
            <li>
                <p>By signing below the parent/guardian is agreeing to the application of these Terms and Conditions in respect of the child&rsquo;s participation in the Drive.</p>
            </li>
            <li>
                <p>Children must not be left unattended at any time. Children are the responsibility of either parents or a guardian at all times.</p>
            </li>
        </ol>
    </li>
</ol>
<p><strong>Note: The terms and conditions for certain drives may differ due to climate issues, drive/road/route conditions. Therefore, we kindly advise customers to carefully review the specific terms and conditions while booking the drive. The eligibility of children to participate in the drives will be at the discretion of the organizers in such cases.</strong></p>
<ol start="16">
    <li>
        <p><strong>Meals and Special Requests:</strong></p>
    </li>
</ol>
<p>The menu for all meals viz., break-fast, lunch &amp; dinner (both vegetarian and non-vegetarian) will be pre-set as per the packages.</p>
<p>The Organisers reserve the right to change the menu if circumstances necessitate such a change without any prior notice to the Participants. If the Participant does not avail the meals (as offered in the menu) for any reason, then no claim can be made for the unutilized meal against the Organisers.</p>
<ol start="17">
    <li>
        <p><strong>Force Majeure:</strong></p>
    </li>
</ol>
<p>Neither party shall be held liable for damages or deficiency in service or loss, injury, expense, etc., in the event of the Drive being delayed or cancelled or aborted after commencing due to any force majeure events including but not limited to Acts of God, natural calamities, prohibition orders, Government restrictions, lockdown, epidemic, pandemic, local unrest, traffic jams, medical emergency, accidents, etc ("Force Majeure Event").</p>
<p>The Organisers shall be under no obligation to resume the Drive on the situation having normalized after the Force Majeure Event.</p>
<ol start="18">
    <li>
        <p><strong>Liability:</strong></p>
        <ol type="a">
            <li>
                <p>The Organisers shall not be responsible and / or liable for any damages caused to the Participant due to circumstances beyond the control of the Organisers (Force Majeure / Vis Majeure / Acts of God).</p>
            </li>
            <li>
                <p>The Organisers shall not be responsible for the temporary or permanent loss of or damage to baggage or personal effects howsoever caused including wilful negligence on the part of any person.</p>
            </li>
            <li>
                <p>The Organisers shall not be responsible for any death, personal injury, sickness, accident, loss, delay, discomfort, increased expenses, any direct loss or damage including financial loss, or loss of contracts or goodwill or for any indirect, special or consequential loss and/or damage or any kind of theft howsoever caused and the Participant hereby forever discharges them of and from all actions, causes of action, suits, debts, obligations, claims and demands whatsoever which the Participant has or hereafter can, shall, or may have.</p>
            </li>
        </ol>
    </li>
    <li>
        <p><strong>Indemnity:</strong></p>
        <ol type="a">
            <li>
                <p>The Participant agrees to indemnify, defend, keep indemnified and hold harmless the Organisers, IIN and Hyundai, their directors, employees, officers, authorized representatives, agents and/or their service providers against any claims, proceedings, damages, judgments (including advocate fees), liability, loss or expenses if any arising:</p>
                <ol type="i">
                    <li>
                        <p>out of or in connection with the participation of the Participant in the Event</p>
                    </li>
                    <li>
                        <p>from the operation, condition or use of the Vehicle by the Participant in the Event, including those arising from a direct or vicarious liability/ claims including from a third party resulting out of his/her act or omission during the entire Event</p>
                    </li>
                    <li>
                        <p>out of any damage, death or injury to persons including that of the Participant itself, or any other third party or any other occupant/co-passenger/s of the vehicle</p>
                    </li>
                    <li>
                        <p>out of any loss or damage to any property of the third party including the vehicle or otherwise as a result of any rash or negligent driving, dis-obeying the traffic rules, over speeding or any other reason of whatsoever nature by the Participant during the Event</p>
                    </li>
                    <li>
                        <p>out of or due to breach by the Participant of the terms and conditions of or the breach of any of the undertakings given by him/her hereunder</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>While participating in the trials, test runs, endurance tests, performance appraisals, or any event as part of the Event, either by himself/herself or with any person to whom he/she has delegated on their behalf or while being driven by any other person while the vehicle is in their possession, the Participant shall not make any claim against the Organisers, its directors, employees, officers, authorized representatives, agents and/or its Service Providers for any loss, damage or injury caused to the Participant or to any other person in any manner whatsoever.</p>
                <p>The indemnity above shall apply Regardless of Cause. For the purposes hereof, &ldquo;Regardless of Cause&rdquo; means without regard to negligence, in whole or in part, of the Party seeking indemnity or of any other person.</p>
            </li>
        </ol>
    </li>
</ol>


<ol start="20">
    <li>
        <p><strong>Intellectual Property Rights and Sharing of Personal Data:</strong></p>
        <ol type="a">
            <li>
                <p>By participating in the Drive, the Participants hereby unconditionally and irrevocably agree and consent that the Organisers may use his/her name and / or photograph and/or video and/or audio for advertising or promotional related activities without demanding any compensation under any circumstances whatsoever from Organisers.</p>
            </li>
            <li>
                <p>The Participant also authorizes the Organisers and consents thereby to willingly use any images/videos clicked by him/her, and which have been shared with the Organisers during the Drive, for use on their social media platforms.</p>
            </li>
            <li>
                <p>The Organisers may use any information collected from the Participant to inform him/her of new products and services. The data provided will be treated in accordance with Indian law.</p>
            </li>
            <li>
                <p>Participant agrees that he/she not share or upload any material (including photos, videos, documents, any other details) relating to the Event, on any platform (including social media platforms), without the prior permission of the Organisers, IIN and Hyundai.</p>
            </li>
            <li>
                <p>Participant agrees that he/she has no objection for using his/her details, data, appraisals of the ride, photos, video footage, diagrams, schematic drawings, social media posts, and other similar and related materials to be utilized for promotional purposes like publishing in print, digital, visual or any other media or any other purposes by the Organisers without any reservation.</p>
            </li>
            <li>
                <p>Participant agrees that he/she expressly and irrevocably assigns all intellectual property rights created, including and not limited to the condition mentioned in the sub-clause above, during the course of participating in the Event to the Organisers without any reservation.</p>
            </li>
        </ol>
    </li>
    <li>
        <p><strong>Governing Law and Jurisdiction:</strong>&nbsp;For any claims, suits, complaints, or disputes relating to the Drive and service provided by the Organisers therein, the competent courts in New Delhi, India alone shall have exclusive jurisdiction.</p>
    </li>
</ol>
                    </div>
                  </div>
                
              </Tab.Pane>
      {/* -----CARNIVAL TERM AND CONDITION======= */}
              <Tab.Pane eventKey="tab-2">
                
              <div className="tctabrow">
                     
                     <div className="olcontentbx">
                     <ol type="1">
                            <li><b>Organisers</b>: Innocean Worldwide Communication Private Limited (“IWI”), authorized
                                by Hyundai Motor India Limited (“Hyundai”) to undertake and organize Hyundai Explorer
                                carnival and related activities, has engaged, on a principal-to-principal basis, ESP 360
                                degree solution Pvt. Ltd (“ESP”), an entity engaged in the business of organising
                                Hyundai Explorers Carnival. Herein, ESP its employees, affiliates, sub-contractors,
                                vendors, etc. are referred to as the “Organisers”.</li>
                            <li><b>Participants &amp; eligibility</b>: By registering for the Carnival and making a
                                payment of the agreed amount to the Organisers, Participant / s (“You”) agree to be
                                bound by these Terms and Conditions. The Carnival are open exclusively to Indian
                                nationals, above 18 years of age, residing in India, who own Hyundai Cars (“Vehicle”).
                                Only such owners of the vehicle, along with their friends and family members are
                                eligible to participate in the Carnivals. The chauffeurs/drivers appointed by the owners
                                are ineligible to participate in the Carnivals. </li>
                            <li><b>Background</b>: The Organisers are organizing an event (hereinafter called as the
                                “Event” or the “Carnival”). The Participant had expressed willingness to participate in
                                the Event as a participant/ occupant/co-passenger of a vehicle and the Organisers have
                                agreed to such request, subject to the terms and conditions of these terms and
                                conditions.</li>
                            <li><b>Third Parties: </b>
                                <ol type="a">
                                    <li>Although ESP is making the arrangements for the Carnivals, its responsibility
                                        is limited. ESP is involved in the bookings related to the Carnival but does not
                                        act as an agent for any party whatsoever. The limits of its responsibility arise
                                        from the fact that it liaises with independent third parties such as independent
                                        tour operators, hotel companies, transportation companies and/ or Carnivals and
                                        other ground operators and suppliers to supply goods and services to
                                        Participants; however, such third parties are not ESP’s employees or agents or
                                        employers or principals. Accordingly, each Participant agrees that ESP shall not
                                        and shall be deemed not to manage, control or supervise the goods or services
                                        they supply and shall not be liable for any acts or omissions, including any
                                        negligence, gross negligence, or reckless or wilful acts, on the part of such
                                        third parties or any other defect in such goods or services. By accepting and
                                        utilising the goods and/ or services of said third parties, each Participant
                                        agrees that ESP shall not be held liable in any way for any injury, loss,
                                        damage, delay, or death or property loss or damage arising from any act or
                                        omission of any such third party.</li>
                                    <li>Each Participant further understands and agrees that Hyundai is no manner
                                        involved in undertaking the Carnivals and consequently, shall not and shall be
                                        deemed not to manage, control or supervise either the Organisers or the third
                                        party suppliers for the Carnivals and shall not be liable for any acts or
                                        omissions, including but not limited to any negligence, gross negligence, or
                                        reckless or wilful acts, on the part of the Organisers or such third parties or
                                        any other defect in the Drives/Carnivals or such goods or services. By
                                        participating in the Drives/Carnivals and accepting and utilising the goods and/
                                        or services of said third parties, each Participant agrees that Hyundai shall
                                        not be held liable in any manner or for whatsoever reason including but not
                                        limited to any injury, loss, damage, delay, or death or property loss or damage
                                        arising from any act or omission of the Organisers or any third party.</li>
                                </ol>
                            </li><li><b>Registration</b>: In order to register for the Drives, the interested Participants
                                has:
                                <ol type="a">
                                    <li>
                                        Submitted their entries (“Entry”) on or before the prescribed last date
                                        mentioned for respective Events by logging-into the microsite,
                                        www.hyundaiexplorers.com/in, interfaced/linked with the website,
                                        www.hyundai.com/in (collectively, the “Website”) and filled the requisite
                                        information (valid phone/mobile number, email ID). The Organisers may at their
                                        discretion, extend or reduce the timeline for submission of the Entries.
                                    </li>
                                    <li>Paid the Entry fee per car with maximum 4 people. </li>
                                    <li>Submitted the required documentation.</li>
                                </ol>
                            </li>
                            <li><b>No-show &amp; Refund of Entry Fee</b>: In the event that a Participant cancels
                                his/her participation in a Drive:
                                <ol type="a">
                                    <li>In case of any no-show of participants, No re-fund will be applicable.</li>
                                    <li>Post registration by any participant (s), if any details are found false or
                                        incorrect, the organising team (ESP) is eligible to disqualify the
                                        participant(s) entry/registration and the registration amount will be refunded
                                        to the participant(s). </li>


                                </ol>
                            </li>
                            <li>
                                <p><b>Selection</b>: All the valid Entries submitted by the Participants will be
                                    evaluated by the Organisers. The decision of the Organisers in selecting the
                                    Participants will be final and binding on all the potential participants and no
                                    correspondence in this regard, shall be entertained under any circumstances of
                                    whatsoever nature. The Organisers reserve the right to restrict number of
                                    Entries/Participants, as they may deem fit at their sole discretion without any
                                    prior notice or justification.
                                </p>
                                <p>Any employee or associate of Hyundai, including but not restricted to vendors –
                                    print, audio and visual media, production houses, Hyundai dealers – is not eligible
                                    to participate in the Drives. The Organisers reserve the right at their sole
                                    discretion to cancel/withdraw participation of any Participant who is found to be an
                                    associate of Hyundai and its decision in this regard would be final and binding.</p>
                                <p>
                                    The Organisers reserve the right at their sole discretion to disqualify any
                                    Participant that they determine to be tampering with the process or the operation of
                                    the Drives, and to be acting in breach or potential breach of these Terms and
                                    Conditions. Eligible Participants understand and agree that they are not compelled
                                    in any way by the Organisers to participate in the Drives and any such participation
                                    is entirely voluntary. The submission of false, incorrect, misleading, or fraudulent
                                    documentation at the time of registration may result in disqualification of the
                                    entry from the Carnival and entire amount / deposit / fee whatever received will be
                                    refunded.</p>
                                <p>
                                    Notwithstanding anything contained in the clauses of the present terms and
                                    conditions, the selection or rejection of any of the Participant shall be at the
                                    sole discretion of the organisers at any time without assigning any reasons and/or
                                    providing notice for the same.
                                </p>
                            </li>
                            <li><b>Participation</b>: The Participant must reach the Carnival Flag-off point in his/
                                her own personal Vehicle along with all original requisite documents on the prescribed
                                date and time for pre-drive verification. In the event that a Participant is unable to
                                reach the Carnival Flag-off point at the scheduled date and time of the Carnival due to
                                any reason whatsoever, his/her booking shall be treated as a "no show" on the Carnival
                                and registration fees will be refunded. Further, the Participant acknowledges and agrees
                                that if he/she arrives at the Carnival starting point not in his/her personal Vehicle
                                but in a borrowed, rented, leased, etc. vehicle then in such event the Participant’s
                                participation will be rejected/disqualified and without prejudice to clause 7, no refund
                                will be payable to the Participant.</li>
                            <li><b>Vehicles</b>:
                                <ol type="a">
                                    <li>The Participant will use his/her own Vehicle in each Drive.</li>
                                    <li>The Vehicle shall only be driven by individuals with a full and valid driving
                                        license for light motor vehicles. Such driving license shall be carried in
                                        original during the Event/Carnival and be shown at the time of registrations.
                                    </li>
                                    <li>The Participant shall ensure that the Vehicle is driven at all times in a
                                        safe and responsible manner in compliance with all applicable road traffic laws
                                        and regulations including Motor Vehicle Act 1988, Central Motor Vehicle Rules
                                        1989 or any special regulations for the terrain driven on, including ensuring
                                        that no-one drives the Vehicle under the influence of alcohol or drugs.</li>
                                    <li>The Participant shall fasten her/his seat belt and cause her/his fellow
                                        passengers to fasten their seat belts at all times in a moving vehicle.</li>
                                    <li>The Participant shall be responsible for the payment of any and all fines,
                                        charges or penalties arising from the use of the Vehicle as a result of criminal
                                        offences / regulatory offences, or the like.</li>
                                    <li>The safekeeping or the Participants’ Vehicles will remain the sole
                                        responsibility of the Participants. Any damage caused to the Vehicles during the
                                        Drive because of accidents, negligence, natural causes shall be solely borne by
                                        the Participants and the Organisers bear no responsibility or liability for any
                                        damages and/or expenses suffered by the Participants on this account.</li>
                                    <li>In the event of any breakdown of a Vehicle during the Drive, the Organisers
                                        bear no responsibility for arranging an alternative vehicle but will provide
                                        assistance for the transportation of the Participants to the scheduled night
                                        halt destination of that day as per the itinerary.</li>
                                    <li>INR. 2000/- Fuel voucher will be provided to participants per car by
                                        organizers and toll charges shall be borne by the Participants for their
                                        Vehicles.
                                    </li>
                                    <li>Organizers shall not be responsible for any claim, dispute, litigation,
                                        action etc. whatsoever in nature by government authorities, third party or any
                                        other person against the Participant during the drive.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Documentation and Insurance</b>: <p>At the time of registration for the
                                    Carnival/Event, the Participant will be required to produce comprehensive Vehicle
                                    insurance, original valid driving licence and registration certificate, pollution
                                    certificate and such other valid legal documents as may be required for the Drive
                                    activity planned during the carnival.</p>
                                <p>For abundant caution, it is clarified that the Entry Fee for each Carnival does not
                                    include costs towards any insurance premium. The Organisers will not obtain any
                                    insurance policy covering any risk. The Participants are advised to seek their own
                                    appropriate personal accident/travel/medical including COVID-19 insurance coverage
                                    as they deem appropriate and at their own cost. All baggage and personal property/s
                                    at all times will be at the Participants’ risk and Participants agree that
                                    Organisers will be not be in a position to guard or protect their belongings at any
                                    time.</p>

                            </li>
                            <li><b>Participant and Co-Passenger:</b>
                                <ol type="a">
                                    <li>Participants are allowed to bring their family members, including children,
                                        or any other individual. However, each Vehicle is only allowed a maximum of 4
                                        people.</li>
                                    <li>Participants cannot transfer the opportunity of a Drive to any third party or
                                        modify/ postpone it to a later date.</li>
                                    <li>No Participant shall have any criminal proceeding pending against them and
                                        the Vehicle shall not be involved in any criminal incident or dispute. Any such
                                        Participants are not eligible to register/ attend the Carnival.</li>
                                    <li>The Participants shall follow a strict no alcohol consumption policy while on
                                        the Drive activity and carnival and consumption and carrying of alcohol is
                                        strictly prohibited during the Carnival. The Organisers will have the right to
                                        immediately disqualify the Participant including attendees who are found to be
                                        breaking this regulation, and they will not be entitled to a refund. In the sake
                                        of the other Participants' safety, the organisers may take any necessary action
                                        that they feel appropriate.</li>
                                    <li>The Participants shall follow the package itinerary. There shall be no
                                        refund, if the Participant fails to join the group at the appointed time of
                                        commencement of the Carnival or join the group later or leave the group before
                                        the culmination of the Carnival. Please note that for all purposes, it shall be
                                        the responsibility of the Participants to reach the place of commencement of the
                                        drive activity during the carnival and register with the Organisers at the
                                        appointed place on the given date and time.</li>
                                    <li>The Participants shall follow the package itinerary. There shall be no refund,
                                        if the Participant fails to join the group at the appointed time of commencement
                                        of the Drive or join the group later or leave the group before the culmination
                                        of the drive. Please note that for all purposes, it shall be the responsibility
                                        of the Participants to reach the place of commencement of the drive and register
                                        with the Organisers at the appointed place on the given date and time.</li>
                                    <li>The Organisers shall not be responsible for any delay or alterations in the
                                        itinerary or expenses incurred - directly or indirectly by Participants due to
                                        natural hazards, accidents, mechanical breakdowns, weather, sickness,
                                        landslides, political closures, prohibition orders, local unrest, traffic jams,
                                        medical emergency, or any untoward incident. The Organisers are not responsible
                                        for any mishap or unforeseen circumstances that may occur at any point of time.
                                    </li>

                                    <li>The Organisers reserve the right to claim any additional expenses because of
                                        delays or alterations in the itinerary, caused by any reason whatsoever.</li>

                                    <li>The Organisers reserve the right to, without prior notice, extend the period
                                        for submission of Entries, amend, alter, vary, or withdraw any departure,
                                        excursion advertised/notified, reverse the order of places to be visited or
                                        activities of similar class/nature if deemed advisable or necessary during the
                                        course of the Carnival.</li>

                                    <li>In the event a Participant misses out on any activity during the Carnival
                                        due to delay on his/her part, the Organisers shall not be held liable to refund
                                        the money paid for the same or in any other manner whatsoever.</li>

                                    <li>Participants will have to strictly adhere to the prescribed timetable for
                                        the day so the Carnival and activities can be completed within the defined
                                        schedule. In case any of the sightseeing schedules are missed due to delays
                                        caused by a Participant or for any other reasons, the same shall not be refunded
                                        to the Participant under any circumstances.</li>

                                    <li>In case a Participant is compelled to discontinue the Drive activity during
                                        the carnival or the Carnival due to any reason whatsoever including illness,
                                        death or loss of any travel documents, no claim shall be entertained for refund
                                        of unutilized services. Further, the Participants shall pay for any towing /
                                        transportation costs involved in moving the Vehicle.</li>

                                    <li>The Organisers reserve the right to withdraw the participation of any
                                        Participant whose behaviour is deemed likely to affect the smooth operation of
                                        the Drive activity during the carnival or adversely affect the enjoyment or
                                        safety of other participants and the Organisers shall be under no liability to
                                        any such expelled Participants.</li>

                                    <li>The positioning and numbering of the vehicles in convoy during the Drive
                                        activity during the carnival will be at sole discretion of organisers and no
                                        representation or communication in this regard will be entertained by the
                                        organisers.
                                    </li>
                                    <li>
                                        The positioning and numbering of the vehicles in convoy during the Drive will be
                                        at sole discretion of organisers and no representation or communication in this
                                        regard will be entertained by the organisers.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Medical Condition: </b>
                                <ol type="a">
                                    <li>Each Participant should only do what he feels capable of doing. Many
                                        activities involve risks. Each Participant must decide himself what risk he
                                        wishes to take. If you are a legal guardian of a Participant who is a minor, you
                                        need to be aware that you will be specifically responsible for assessing the
                                        risk and for making decisions on behalf of such Participant.</li>
                                    <li>If a medical condition has been disclosed by a Participant, the Organisers
                                        shall make an effort to provide basic medical help to such Participant, but
                                        under no circumstances shall the Organisers be held liable to any injury,
                                        disability, death, sickness etc., that the Participant may suffer or be bound to
                                        provide any emergency assistance or refund the money.</li>

                                </ol>
                            </li>
                            <li><b>Drive Package: </b> The details of inclusions and exclusions of the Carnival are as
                                follows:
                                <ol type="a">
                                    <li><b>Inclusions:</b>
                                        <ol type="i">
                                            <li>Meals viz., breakfast, lunch and dinner (both vegetarian and
                                                non-vegetarian) as per the Menu offerings and in-vehicle refreshments.
                                            </li>
                                            <li>Permits and entry and other charges for activities and experiences
                                                during the drive activity during the carnival. </li>
                                            <li>Gifts, giveaways and all parking charges. </li>

                                        </ol>
                                    </li>
                                </ol>
                                <ol type="a">
                                    <li><b>Exclusions:</b>
                                        <ol type="i">
                                            <li>Flight tickets to and from respective Drive locations and airport
                                                transfers</li>
                                            <li>Toll charges and Vehicle servicing, repairs and traffic fines
                                                Insurances, pollution under control certificates, etc.

                                            </li>
                                            <li>Any additional service such as refreshments/meals purchased during the
                                                drive activity during the carnival from other vendors who are not par of
                                                the
                                                carnival.
                                            </li>
                                            <li>Any charges for carrying video camera or still camera, subsistence or
                                                other cost incurred prior to official start date of the Drive and after
                                                official end date of the Drive activity of the Carnival. </li>
                                            <li>Any kind of personal expenses or optional tours, extra meals and
                                                medical expenses during the carnival drive activity or Carnival. </li>

                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><b>Hotel/venue:</b>
                                <p>The Organisers will make best efforts to procure the best available location in the
                                    price range keeping in mind the comfort and convenience of the Participants as per
                                    the itinerary as priority and will be selected based on strong internal assessment.
                                    While the Organisers will make best efforts to ensure the delivery of services that
                                    are to be provided by the venue, they act as a facilitator for the Participants and
                                    the venue and shall under no circumstance responsible for any additional requirement
                                    by participant(s). The Organisers shall not accept any complaints from the
                                    Participants with respect to services provided by the venue and that any grievance
                                    or complaint shall be directly lodged with the concerned venue management.</p>

                            </li>
                            <li><b>Children:</b>
                                <ol type="a">
                                    <li>Children 5 years and above are eligible to participate in the Drive activities
                                        during the carnival &amp; activities at the venue during the carnival in the company
                                        of their parents/guardians.</li>
                                    <li>The parent/guardian signing below agrees to be responsible for his/her children
                                        and his/her actions and will ensure he/she complies with his/her obligations.
                                    </li>
                                    <li>By signing below the parent/guardian is agreeing to the application of these
                                        Terms and Conditions in respect of the child’s participation in the Drive
                                        activity and carnival.</li>
                                    <li>Children must not be left unattended at any time. Children are the
                                        responsibility of either parents or a guardian at all times. </li>

                                </ol>
                            </li>
                            <li><b>Meals and Special Requests:</b>
                                <p>The menu for all meals viz., break-fast, lunch &amp; dinner (both vegetarian and
                                    non-vegetarian) will be pre-set as per the packages.</p>
                                <p>The Organisers reserve the right to change the menu if circumstances necessitate such
                                    a change without any prior notice to the Participants. If the Participant does not
                                    avail the meals (as offered in the menu) for any reason, then no claim can be made
                                    for the unutilized meal against the Organisers.
                                </p>
                            </li>
                            <li><b>Force Majeure:</b>
                                <p>Neither party shall be held liable for damages or deficiency in service or loss,
                                    injury, expense, etc., in the event of the Drive being delayed or cancelled or
                                    aborted after commencing due to any force majeure events including but not limited
                                    to Acts of God, natural calamities, prohibition orders, Government restrictions,
                                    lockdown, epidemic, pandemic, local unrest, traffic jams, medical emergency,
                                    accidents, etc ("Force Majeure Event").</p>
                                <p>The Organisers shall be under no obligation to resume the Drive on the situation
                                    having normalized after the Force Majeure Event.
                                </p>
                            </li>
                            <li><b>Liability:</b>
                                <ol type="a">
                                    <li>The Organisers shall not be responsible and / or liable for any damages caused
                                        to the Participant due to circumstances beyond the control of the Organisers
                                        (Force Majeure / Vis Majeure / Acts of God). </li>
                                    <li>The Organisers shall not be responsible for the temporary or permanent loss of
                                        or damage to baggage or personal effects howsoever caused including wilful
                                        negligence on the part of any person.</li>
                                    <li>The Organisers shall not be responsible for any death, personal injury,
                                        sickness, accident, loss, delay, discomfort, increased expenses, any direct loss
                                        or damage including financial loss, or loss of contracts or goodwill or for any
                                        indirect, special or consequential loss and/or damage or any kind of theft
                                        howsoever caused and the Participant hereby forever discharges them of and from
                                        all actions, causes of action, suits, debts, obligations, claims and demands
                                        whatsoever which the Participant has or hereafter can, shall, or may have.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Indemnity: </b>

                                <ol type="a">
                                    <li>The Participant agrees to indemnify, defend, keep indemnified and hold
                                        harmless the Organisers, IWI and Hyundai, their directors, employees, officers,
                                        authorized representatives, agents and/or their service providers against any
                                        claim, damages, judgments (including advocate fees), liability, loss or expenses
                                        if any arising:
                                    </li>
                                    <li>
                                        <ol type="i">
                                            <li>out of or in connection with the participation of the Participant in the
                                                Event</li>
                                            <li>from the operation, condition or use of the Vehicle by the Participant
                                                in
                                                the Event, including those arising from a direct or vicarious liability/
                                                claims including from a third party resulting out of his/her act or
                                                omission
                                                during the entire Event</li>
                                            <li>out of any damage, death or injury to persons including that of the
                                                Participant itself, or any other third party or any other
                                                occupant/co-passenger/s of the vehicle</li>
                                            <li>out of any loss or damage to any property of the third party including
                                                the
                                                vehicle or otherwise as a result of any rash or negligent driving,
                                                dis-obeying the traffic rules, over speeding or any other reason of
                                                whatsoever nature by the Participant during the Event</li>
                                            <li>out of or due to breach by the Participant of the terms and conditions
                                                of or
                                                the breach of any of the undertakings given by him/her hereunder</li>
                                        </ol>
                                    </li>
                                    <li>Participant agrees that he/she will not file in person/through any family member
                                        and/or third party any applications, criminal and/or civil proceedings,
                                        administrative, tort, motor vehicles act/rules, consumer or otherwise of
                                        whatsoever
                                        acts, rules, regulations in any courts or forum in India against any Organiser,
                                        its
                                        directors, employees, officers, authorized representatives, agents and/or its
                                        service providers to claim any damages or relief in connection with the Event or
                                        any
                                        matter related to it.</li>
                                    <li>While participating in the trials, test runs, endurance tests, performance
                                        appraisals, or any event as part of the Event, either by himself/herself or with
                                        any
                                        person to whom he/she has delegated on their behalf or while being driven by any
                                        other person while the vehicle is in their possession, the Participant shall not
                                        shall not make any claim against the Organisers, its directors, employees,
                                        officers,
                                        authorized representatives, agents and/or its Service Providers for any loss,
                                        damage
                                        or injury caused to the Participant or to any other person in any manner
                                        whatsoever.
                                    </li>
                                    <p>The indemnity above shall apply Regardless of Cause. For the purposes hereof,
                                        “Regardless of Cause” means without regard to negligence, in whole or in part,
                                        of
                                        the Party seeking indemnity or of any other person. </p>
                                </ol>

                            </li>
                            <li><b>Intellectual Property Rights and Sharing of Personal Data: </b>
                                <ol type="a">
                                    <li>By participating in the Drive, the Participants hereby unconditionally and
                                        irrevocably agree and consent that the Organisers may use his/her name and / or
                                        photograph and/or video and/or audio for advertising or promotional related
                                        activities without demanding any compensation under any circumstances whatsoever
                                        from Organisers.</li>
                                    <li>The Participant also authorizes the Organisers to willingly use any
                                        images/videos
                                        clicked by him/her, and which have been shared with the Organisers during the
                                        Drive,
                                        for use on their social media platforms.</li>
                                    <li>The Organisers may use any information collected from the Participant to inform
                                        him/her of new products and services. The data provided will be treated in
                                        accordance with Indian law.</li>
                                    <li>Participant agrees that he/she not share or upload any material (including
                                        photos,
                                        videos, documents, any other details) relating to the Event, on any platform
                                        (including social media platforms), without the prior permission of the
                                        Organisers,
                                        IWI and Hyundai. </li>
                                    <li>Participant agrees that he/she has no objection for using his/her details, data,
                                        appraisals of the ride, photos, video footage, diagrams, schematic drawings,
                                        social
                                        media posts, and other similar and related materials to be utilized for
                                        promotional
                                        purposes like publishing in print, digital, visual or any other media or any
                                        other
                                        purposes by the Organisers without any reservation.</li>
                                    <li>Participant agrees that he/she expressly and irrevocably assigns all
                                        intellectual
                                        property rights created, including and not limited to the condition mentioned in
                                        the
                                        sub-clause above, during the course of participating in the Event to the
                                        Organisers
                                        without any reservation.</li>
                                </ol>
                            </li>
                            <li><b>Governing Law and Jurisdiction:</b> For any claims, suits, complaints, or disputes
                                relating to the Drive and service provided by the Organisers therein, the competent
                                courts
                                in New Delhi, India alone shall have exclusive jurisdiction.</li>
                        </ol>
                         </div>
                         </div>
                
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
};

export default TermConditionPage;
