import React, { useEffect } from 'react'

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact us  | HyundaiExplorers";
  }, []);
  return ( 
    
  <section className="loginformbox pt-5 pb-5 mtgap50 my-5">
  <div className="container">
    <div className="row justify-content-center align-items-center m-auto">
      <div className="col-sm-6">
        <div className="bg-mode shadow rounded-3 text-center overflow-hidden">
          <div className="p-3 p-sm-4 p-md-5">
            <h1 className="mb-2 h2">Email us</h1>
            <p className="mb-0">
              {" "}
              Want us to send you more details on the drive?{" "}
            </p>
            <p className="mb-4"> Need answers to a few questions? </p>
            <p className="mb-2"> Here's how you can reach us. </p>
            <div className="text-center mb-4">
              <button type="submit" className="btn btn-primary">
                info@hyundaiexplorers.com
              </button>
            </div>
            <p className="mt-4 mb-1">
              {" "}
              or call 8000851616 for Adventure assistance and call 9971104651
              for Carnival assistance.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  
  )
}

export default ContactUs