import React, { useEffect, useState } from "react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    let endDate;
    if (targetDate instanceof Date) {
      endDate = targetDate;
    } else {
      endDate = new Date();
    }
    
    const now = new Date();
    const difference = endDate.getTime() - now.getTime();

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const formatTime = (value) => {
    // Pad single-digit numbers with leading zero
    return value < 10 ? `0${value}` : value;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <ul>
      <li>
        <span>{formatTime(timeLeft.days)}</span>days
      </li>
      <li>
        <span>{formatTime(timeLeft.hours)}</span>hrs
      </li>
      <li>
        <span>{formatTime(timeLeft.minutes)}</span>min
      </li>
      <li>
        <span>{formatTime(timeLeft.seconds)}</span>sec
      </li>
    </ul>
  );
};

export default CountdownTimer;
