import React from 'react'
import { Link } from 'react-router-dom'
import AdventureSlider from './SubComponent/AdventureSlider'
import CarnivalSlider from './SubComponent/CarnivalSlider'
import "./category.css"
const HomeEvent = () => {
  return (
    <>
      <section className="blueband mb-4">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h5 className="h5">WHAT'S NEW</h5>
          <h3 className="h3">See what’s happening</h3>
        </div>
      </div>
    </div>
  </section>
  <section className="adventuremainbx pt-3 pb-3">
    <div className="container">
      <div className="row">
    
        <AdventureSlider/>
        <CarnivalSlider/>
        
      </div>
    </div>
  </section>
    </>
  )
}

export default HomeEvent
