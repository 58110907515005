import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
const CookieConsentComponent = () => {
  // 
  return (
    <div>
      <CookieConsent
        enableDeclineButton
        declineButtonText="X"
        declineButtonStyle={{
          position: "absolute",
          top: "0",
          right: "0",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "#002b5f",
          fontSize: "25px ",
          fontWeight: "bold",
          padding: "0",
          margin: "8px",
        }}
        location="bottom"
        buttonText="Accept & Close"
        cookieName="myAwesomeCookieName2"
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10001,
          padding: "15px 0 15px",
          backgroundColor: "rgb(239, 241, 242)",
          display: "flex",
          padding: "20px 0",
        }}
        buttonStyle={{
          background: "#002b5f",
          color: "#fff",
          border: "5px solid #002b5f",
          fontSize: "13px",
        }}
        expires={150}
      >
        <h2 style={{ color: "#01244d" }}>About Cookies On This Site</h2>
        <span style={{ color: "#01244d", fontSize: "20px", lineHeight: "1.5" }}>
          We use cookies to analyze site usage and improve our website and
          services. By continuing to browse the site you are agreeing to our use
          of cookies. For more information, read our{" "}
          <Link style={{ color: "rgb(138 124 245)" }} to="/legal-disclaimer">
            {" "}
            Cookies Policy
          </Link>
          .
        </span>
      </CookieConsent>
    </div>
  );
};

export default CookieConsentComponent;
